import React, { ButtonHTMLAttributes, useState, useEffect } from 'react';
import css from './Button.module.scss';
import classes from 'classnames';
import { Spinner } from 'components/Spinner';
import { ButtonBaseProps } from 'common/interfaces/ButtonBaseProps';
import { Icon, IIconProps } from 'components/Icon';

// Tooltip imports
import { ToggleLayer } from 'react-laag';
import { AnchorEnum } from 'react-laag/dist/ToggleLayer/types';
import ResizeObserver from 'resize-observer-polyfill';
import { getUniqueId } from 'common/utils/getUniqueId';
import { BadgeIcon } from 'components/BadgeIcon';
import { RemoveProp } from 'common/types/TypeHelpers';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';

type IButtonProps = ButtonBaseProps & {
	htmlType?: 'button' | 'submit' | 'reset';
} & RemoveProp<
		React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
		'type'
	>;

export type TTooltipPositions = 'top' | 'right' | 'bottom' | 'left';

// Tooltip Props
export interface ITooltipProps {
	tooltipPosition?: TTooltipPositions;
	tooltipMessage?: string;
	tooltipAlwaysShown?: boolean;
}

const positionMap: { [position in TTooltipPositions]: AnchorEnum } = {
	top: 'TOP_CENTER',
	right: 'RIGHT_CENTER',
	bottom: 'BOTTOM_CENTER',
	left: 'LEFT_CENTER'
};

export const Button = React.forwardRef((props: IButtonProps & ITooltipProps, ref: any) => {
	const {
		type = 'primary',
		size = 'medium',
		children,
		onClick,
		disabled,
		isLoading,
		loadingLabel = 'Loading',
		tooltipMessage,
		tooltipPosition = 'top',
		className,
		tooltipAlwaysShown,
		htmlType,
		iconLeft,
		iconRight,
		classNames,
		focusRingColor = 'blue'
	} = props;

	if (!iconLeft && !iconRight && !tooltipMessage && !children) {
		throw new Error(
			'For accessibility you must include the tooltipMessage when there is an icon and no children.'
		);
	}

	const [uniqueId] = useState(getUniqueId());

	const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout>();

	useEffect(() => {
		return () => {
			clearTimeout(hoverTimeout);
		};
	}, [hoverTimeout]);

	const ButtonContents = () => (
		<>
			{isLoading && (
				<Spinner
					size={
						type === 'link' || type === 'linkSmall' || type === 'sidebarLink'
							? 'smallest'
							: 'small'
					}
					type={type === 'danger' ? 'lighter' : 'darker'}
					ariaHidden={true}
					className={css.spinner}
				/>
			)}
			{isLoading ? (
				loadingLabel
			) : (
				<span className={classes(css.buttonContent, classNames?.buttonContent)}>
					{(() => {
						if (iconLeft) {
							const iconProps = removePropertiesFromObjects(
								['badgeAmount'],
								iconLeft
							) as IIconProps;
							if (iconLeft.badgeAmount) {
								return (
									<BadgeIcon icon={iconProps} context={type} className={css.iconLeft}>
										{iconLeft.badgeAmount < 100 ? iconLeft.badgeAmount : '99+'}
									</BadgeIcon>
								);
							}
							return (
								<Icon
									{...iconProps}
									context={type}
									className={classes(css.iconLeft, iconLeft.className)}
								></Icon>
							);
						}
					})()}
					{children}
					{iconRight && (
						<Icon
							{...iconRight}
							context={type}
							className={classes(css.iconRight, iconRight.className)}
						></Icon>
					)}
				</span>
			)}
			<div className={classes(css.focusRing, css[focusRingColor], classNames?.focusRing)}></div>
		</>
	);

	const buttonClasses = classes(
		css[type],
		css.button,
		className,
		{
			[css.hasIcon]: iconLeft || iconRight,
			[css.hasChildren]: children
		},
		css[size],
		'button'
	);

	const propsWithRemovedCustomProps = removePropertiesFromObjects(
		[
			'type',
			'size',
			'children',
			'disabled',
			'isLoading',
			'loadingLabel',
			'tooltipMessage',
			'tooltipPosition',
			'tooltipAlwaysShown',
			'htmlType',
			'iconLeft',
			'iconRight',
			'classNames',
			'focusRingColor'
		],
		props
	);

	const derivedButtonProps: ButtonHTMLAttributes<HTMLButtonElement> = {
		type: 'button',
		className: buttonClasses,
		onClick,
		disabled: disabled || isLoading,
		'aria-busy': isLoading,
		'aria-labelledby': tooltipMessage && uniqueId
	};

	const buttonProps: ButtonHTMLAttributes<HTMLButtonElement> = {
		...propsWithRemovedCustomProps,
		...derivedButtonProps
	};

	if (tooltipMessage) {
		return (
			<ToggleLayer
				ResizeObserver={ResizeObserver}
				fixed
				isOpen={tooltipAlwaysShown}
				placement={{ anchor: positionMap[tooltipPosition], autoAdjust: true, triggerOffset: 2 }}
				renderLayer={({ isOpen, layerProps }) =>
					isOpen && (
						<div {...layerProps} className={css.tooltip} role='tooltip' id={uniqueId}>
							{tooltipMessage}
						</div>
					)
				}
			>
				{({ triggerRef, open, close }) => {
					return (
						<button
							{...buttonProps}
							ref={triggerRef}
							onFocus={open}
							onMouseEnter={() => {
								const timeout = setTimeout(() => {
									open();
								}, 100);
								setHoverTimeout(timeout);
							}}
							onMouseLeave={() => {
								close();
								clearTimeout(hoverTimeout);
							}}
							onClick={event => {
								onClick(event);
								close();
							}}
							onBlur={close}
							type={htmlType}
						>
							<ButtonContents />
						</button>
					);
				}}
			</ToggleLayer>
		);
	} else {
		return (
			<button {...buttonProps} id={uniqueId} type={htmlType} ref={ref}>
				<ButtonContents />
			</button>
		);
	}
});
