import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Spinner } from 'components/Spinner';
import { canUseAudits, useCurrentUser } from 'api/auth';

interface IOrgHasAudits {
	ComponentToRender: React.FC;
}

export const OrgHasAuditsRouteGuard: React.FC<IOrgHasAudits> = props => {
	const { data: user } = useCurrentUser();
	const { ComponentToRender } = props;
	const history = useHistory();

	useEffect(() => {
		if (user?.role) {
			if (!canUseAudits(user)) {
				history.push('/');
			}
		}
	}, [user, history]);

	if (user?.role) {
		return <ComponentToRender />;
	} else {
		return (
			<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
		);
	}
};
