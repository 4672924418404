import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/logo/logo_black_2023.svg';
import { useAuth } from 'providers/Auth/AuthProvider';
import { useCurrentUser } from 'api/auth';
import { DropdownList } from 'components/DropdownList';
import { DropdownItem } from 'common/types/DropdownItem';
import { BottomMenuContent } from './BottomMenuContent';

import css from './Header.module.scss';

interface HeaderProps
	extends React.PropsWithChildren<{
		setIsSidebarShown: React.Dispatch<React.SetStateAction<boolean>>;
		isSidebarShown: boolean;
	}> {}

export const Header = ({ setIsSidebarShown, isSidebarShown }: HeaderProps) => {
	const { logout } = useAuth();
	const { data: currentUser } = useCurrentUser();

	const dropdownItems: DropdownItem[] = [
		{
			title: 'Common Questions',
			action: 'https://www.sxanpro.com/utw84vg8cjxikmeajnptx',
			icon: { type: 'Question' }
		},
		{ title: 'About SxanPro', action: 'https://www.sxanpro.com/', icon: { type: 'Information' } },
		{ title: 'Sign Out', action: logout, icon: { type: 'Logout' } }
	];

	return (
		<div className={css.header}>
			<div className={css.headerNav}>
				<Link to='/' className={css.logoContainer}>
					<Logo className={css.logo} />
				</Link>
				{currentUser && (
					<>
						<DropdownList
							items={dropdownItems}
							ContentBottom={BottomMenuContent}
							type='ghost'
							size='small'
							iconLeft={{ type: 'User', size: 'large', className: css.userDropdownIcon }}
							iconRight={{
								type: 'CaretDown',
								color: 'green',
								className: css.userDropdownCaret
							}}
							dropdownPosition='stretch'
							className={css.userDropdown}
							classNames={{ button: css.userDropdownButton }}
							label={currentUser.name}
						/>
					</>
				)}
				<button
					className={css.menuToggle}
					aria-controls='primary-menu'
					aria-expanded='false'
					onClick={() => {
						setIsSidebarShown(!isSidebarShown);
					}}
				>
					<div className={css.target}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</button>
			</div>
		</div>
	);
};
