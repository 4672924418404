import classes from 'classnames';
import { StyleProps } from 'common/types/StyleProps';
import css from './Container.module.scss';

interface ContainerProps extends React.PropsWithChildren<StyleProps> {
	isFullWidth?: boolean;
}

export const Container = ({ children, className, style, isFullWidth }: ContainerProps) => {
	return (
		<div
			className={classes(css.container, className, { [css.isFullWidth]: isFullWidth })}
			style={style}
		>
			{children}
		</div>
	);
};
