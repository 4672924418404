import { useState } from 'react';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import css from './AppFrame.module.scss';
import React from 'react';
import { Container } from 'components/Container';
import classes from 'classnames';

export const AppFrame = ({ children }: React.PropsWithChildren<unknown>) => {
	const [isSidebarShown, setIsSidebarShown] = useState(false);

	return (
		<div className={css.appFrame}>
			<a href='#content' id='skipNavLink' className={css.skipNavigation}>
				Skip Navigation
			</a>
			<Header isSidebarShown={isSidebarShown} setIsSidebarShown={setIsSidebarShown} />

			<div className={css.content}>
				<div className={classes(css.sidebarContainer, { [css.isSidebarShown]: isSidebarShown })}>
					<Sidebar />
				</div>
				<div className={css.mainContainer}>
					<Container isFullWidth={true}>
						<main id='content' className={css.main}>
							{children}
						</main>
					</Container>
				</div>
			</div>
		</div>
	);
};
