import React from 'react';
import css from './Alert.module.scss';
import classes from 'classnames';
import { Button } from 'components/Button/Button';
import { TAlertTypes } from 'common/types/TAlertTypes';

export interface IAlertProps {
	className?: string;
	alertContentClassName?: string;
	type?: TAlertTypes;
	isDismissible?: boolean;
	setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
	accessibilityRole?: 'alert' | 'status';
}

export const Alert: React.FC<IAlertProps> = props => {
	const {
		type = 'info',
		className,
		alertContentClassName,
		children,
		isDismissible = false,
		setIsShown,
		accessibilityRole = 'alert'
	} = props;

	return (
		<div className={classes(css.alert, css[type], className)} role={accessibilityRole}>
			<span className={classes(css.text, alertContentClassName)}>{children}</span>
			{isDismissible && (
				<Button
					type='link'
					onClick={() => setIsShown(false)}
					iconLeft={{
						type: 'Close',
						color: type === 'warning' ? 'black' : 'white'
					}}
					tooltipMessage='Dismiss Message'
					className={css.dismissMessage}
				></Button>
			)}
		</div>
	);
};
