import { useAuth } from 'providers/Auth/AuthProvider';
import { Route, RouteProps, Redirect } from 'react-router-dom';

// A route component that extends Route
// Uses the auth custom hook to check if current user is authenticated
// IF not authenticated then redirects to the /login page.
// Passes the last visited location to the Login state so upon successful login
// The user can be Redirected back to desired route
export const PrivateRoute = ({ children, location, ...routeProps }: RouteProps) => {
	const { isAuthenticated } = useAuth();

	const passed = isAuthenticated();

	return (
		<Route {...routeProps}>
			{passed && children}
			{!passed && <Redirect to={{ pathname: '/login', state: { from: location } }} />}
		</Route>
	);
};
