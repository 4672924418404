import { useState } from 'react';
import css from './Icon.module.scss';
import classes from 'classnames';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { getUniqueId } from 'common/utils/getUniqueId';
import { ToggleLayer, Arrow } from 'react-laag';
import { ButtonTypes } from 'common/types/ButtonTypes';

// Icons
import { ReactComponent as Home } from 'assets/svg/home-outline.svg';
import { ReactComponent as Clipboard } from 'assets/svg/clipboard-text-outline.svg';
import { ReactComponent as Chart } from 'assets/svg/chart-box-outline.svg';
import { ReactComponent as User } from 'assets/svg/account-outline.svg';
import { ReactComponent as Bell } from 'assets/svg/bell-outline.svg';
import { ReactComponent as Folder } from 'assets/svg/folder-outline.svg';
import { ReactComponent as Package } from 'assets/svg/package-variant-closed.svg';
import { ReactComponent as Office } from 'assets/svg/office-building-outline.svg';
import { ReactComponent as CaretUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as CaretDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as CaretUpSolid } from 'assets/svg/chevron-up-solid.svg';
import { ReactComponent as CaretDownSolid } from 'assets/svg/chevron-down-solid.svg';
import { ReactComponent as CaretLeft } from 'assets/svg/chevron-left.svg';
import { ReactComponent as CaretRight } from 'assets/svg/chevron-right.svg';
import { ReactComponent as DotsHorizontal } from 'assets/svg/dots-horizontal.svg';
import { ReactComponent as DotsVertical } from 'assets/svg/dots-vertical.svg';
import { ReactComponent as Search } from 'assets/svg/magnify.svg';
import { ReactComponent as Trash } from 'assets/svg/trash-can-outline.svg';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as Minus } from 'assets/svg/minus.svg';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { ReactComponent as CloseCircleOutline } from 'assets/svg/close-circle-outline.svg';
import { ReactComponent as Upload } from 'assets/svg/cloud-upload-outline.svg';
import { ReactComponent as Check } from 'assets/svg/check.svg';
import { ReactComponent as CheckCircle } from 'assets/svg/checkbox-marked-circle.svg';
import { ReactComponent as CheckCircleOutline } from 'assets/svg/check-circle-outline.svg';
import { ReactComponent as Document } from 'assets/svg/file-document-outline.svg';
import { ReactComponent as SpinnerThin } from 'assets/svg/spinner-thin.svg';
import { ReactComponent as Alert } from 'assets/svg/alert-outline.svg';
import { ReactComponent as Information } from 'assets/svg/information-outline.svg';
import { ReactComponent as Logout } from 'assets/svg/logout-variant.svg';
import { ReactComponent as Question } from 'assets/svg/message-question-outline.svg';
import { ReactComponent as List } from 'assets/svg/format-list-bulleted.svg';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { ReactComponent as Move } from 'assets/svg/move.svg';
import { ReactComponent as Email } from 'assets/svg/email.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as EditList } from 'assets/svg/playlist-edit.svg';
import { ReactComponent as Location } from 'assets/svg/map-marker.svg';
import { ReactComponent as ImportantLocation } from 'assets/svg/map-marker-outline.svg';
import { ReactComponent as Bin } from 'assets/svg/inbox-multiple-outline.svg';
import { ReactComponent as Download } from 'assets/svg/download-outline.svg';
import { ReactComponent as Copy } from 'assets/svg/content-copy.svg';

export type IconSize = 'smallest' | 'small' | 'medium' | 'large' | 'largest' | 'giant';

export type IconColors = 'black' | 'white' | 'red' | 'green' | 'gray' | 'grayLight' | 'initial';

const iconMap = {
	Home,
	Clipboard,
	Chart,
	User,
	Bell,
	Folder,
	Package,
	Office,
	CaretUp,
	CaretDown,
	CaretUpSolid,
	CaretDownSolid,
	CaretLeft,
	CaretRight,
	DotsHorizontal,
	DotsVertical,
	Search,
	Trash,
	Plus,
	Minus,
	Close,
	CloseCircleOutline,
	Upload,
	Check,
	CheckCircle,
	CheckCircleOutline,
	Document,
	SpinnerThin,
	Alert,
	Information,
	Logout,
	Question,
	List,
	Edit,
	Move,
	Email,
	Phone,
	EditList,
	ImportantLocation,
	Location,
	Bin,
	Download,
	Copy
};

export type IconTypes = keyof typeof iconMap;

export interface IIconProps {
	className?: string;
	type: IconTypes;
	size?: IconSize;
	color?: IconColors;
	style?: React.CSSProperties;
	context?: ButtonTypes;
	tooltip?: string | React.ReactNode;
	preTooltipAccessibilityMessage?: string;
	postTooltipAccessibilityMessage?: string;
	tooltipAlwaysShown?: boolean;
	marginSize?:
		| 'right-small'
		| 'right-medium'
		| 'right-large'
		| 'left-small'
		| 'left-medium'
		| 'left-large';
	strokeWidth?: 'thin' | 'thick';
}

export const Icon: React.FC<IIconProps & React.SVGProps<SVGSVGElement>> = props => {
	const {
		className,
		type,
		size = 'medium',
		color = 'black',
		style,
		context,
		tooltip,
		tooltipAlwaysShown,
		marginSize,
		preTooltipAccessibilityMessage,
		postTooltipAccessibilityMessage,
		strokeWidth = 'thin'
	} = props;

	const iconClasses = classes(
		css.icon,
		className,
		css[size],
		css[color],
		css[type],
		css[context],
		css[strokeWidth],
		{ [css[`margin-${marginSize}`]]: marginSize }
	);

	const iconProperties = {
		...removePropertiesFromObjects(
			[
				'type',
				'size',
				'color',
				'context',
				'marginSize',
				'tooltipAlwaysShown',
				'preTooltipAccessibilityMessage',
				'postTooltipAccessibilityMessage'
			],
			props
		),
		className: iconClasses,
		style
	};

	const [uniqueId] = useState(getUniqueId());

	const [mutableObject] = useState<{
		onMouseLeaveTimeout?: NodeJS.Timeout;
		onMouseEnterTimeout?: NodeJS.Timeout;
		close?: () => void;
	}>({});

	const IconTag = iconMap[type];

	if (tooltip) {
		return (
			<ToggleLayer
				ResizeObserver={ResizeObserver}
				fixed
				isOpen={tooltipAlwaysShown}
				placement={{ anchor: 'RIGHT_CENTER', autoAdjust: true, triggerOffset: 10 }}
				renderLayer={({ isOpen, layerProps, arrowStyle, layerSide }) =>
					isOpen && (
						<div
							{...layerProps}
							className={css.tooltip}
							role='alert'
							id={uniqueId + 'tooltip'}
							onMouseEnter={() => {
								clearTimeout(mutableObject.onMouseLeaveTimeout);
							}}
							onMouseLeave={() => {
								if (mutableObject?.close) {
									mutableObject.close();
								}
							}}
						>
							{preTooltipAccessibilityMessage && (
								<span className='screenReaderText'>
									{preTooltipAccessibilityMessage}
								</span>
							)}
							{tooltip}
							{postTooltipAccessibilityMessage && (
								<span className='screenReaderText'>
									{postTooltipAccessibilityMessage}
								</span>
							)}
							<Arrow
								style={arrowStyle}
								layerSide={layerSide}
								backgroundColor='#04ac8c'
								roundness={1}
							/>
						</div>
					)
				}
			>
				{({ triggerRef, open, close, isOpen }) => {
					const tooltipIconProperties: React.SVGProps<SVGSVGElement> = {
						...iconProperties,
						tabIndex: 0,
						ref: triggerRef,
						'aria-label': 'Tooltip Message',
						id: `${uniqueId}button`,
						'aria-expanded': isOpen,
						onMouseEnter: () => {
							mutableObject.close = close;
							mutableObject.onMouseEnterTimeout = setTimeout(() => {
								open();
							}, 100);
						},
						onMouseLeave: () => {
							clearTimeout(mutableObject.onMouseEnterTimeout);
							mutableObject.onMouseLeaveTimeout = setTimeout(() => {
								close();
							}, 500);
						},
						onClick: event => {
							event.stopPropagation();
							close();
							setTimeout(() => {
								open();
							}, 1);
						},
						onKeyUp: event => {
							if (event.code === 'Escape') {
								close();
							} else if (event.code === 'Enter') {
								close();
								setTimeout(() => {
									open();
								}, 1);
							}
						},
						onFocus: open,
						onBlur: close,
						className: classes(
							css.icon,
							className,
							{ [css[`margin-${marginSize}`]]: marginSize },
							iconProperties?.className
						)
					};

					return <IconTag {...iconProperties} {...tooltipIconProperties} />;
				}}
			</ToggleLayer>
		);
	}

	return <IconTag {...iconProperties} />;
};
