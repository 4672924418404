import React, { useEffect, useState, useRef } from 'react';
import css from './DropdownList.module.scss';
import classes from 'classnames';
import { DropdownItem as TDropdownItem } from 'common/types/DropdownItem';
import { TToggle } from 'common/hooks/useToggle';
import { Dropdown } from 'components/Dropdown';
import { DropdownProps } from 'components/Dropdown';
import { RemoveProp } from 'common/types/TypeHelpers';
import { Link } from 'components/Link';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';

interface ListProps {
	items: TDropdownItem[];
	toggle?: TToggle;
}

interface DropdownItemProps {
	index: number;
	dropdownItem: TDropdownItem;
	handleKeydown: (event: React.KeyboardEvent<any>, dropdownItem: TDropdownItem) => void;
	setRefByIndex: (ref: React.MutableRefObject<HTMLElement>, index: number) => void;
	onClick?: () => void;
}

const DropdownItem: React.FC<DropdownItemProps> = props => {
	const { index, dropdownItem, handleKeydown, setRefByIndex, onClick } = props;
	const ref = useRef(null);

	useEffect(() => {
		setRefByIndex(ref, index);
	}, [index, setRefByIndex]);

	switch (typeof dropdownItem.action) {
		case 'function':
			return (
				<li key={index}>
					<Button
						ref={ref}
						onClick={() => {
							onClick && onClick();
							typeof dropdownItem.action === 'function' && dropdownItem.action();
						}}
						onKeyDown={event => handleKeydown(event, dropdownItem)}
						type='link'
						classNames={{ buttonContent: css.buttonContent }}
					>
						{dropdownItem.icon && <Icon {...dropdownItem.icon} className={css.icon} />}
						{dropdownItem.title}
					</Button>
				</li>
			);
		case 'string':
			return (
				<li key={index}>
					<Link
						ref={ref}
						to={dropdownItem.action}
						onClick={() => {
							onClick && onClick();
						}}
						onKeyDown={event => handleKeydown(event, dropdownItem)}
						classNames={{ buttonContent: css.buttonContent }}
						external={dropdownItem.action.startsWith('http')}
					>
						{dropdownItem.icon && <Icon {...dropdownItem.icon} className={css.icon} />}
						{dropdownItem.title}
					</Link>
				</li>
			);
	}
};

const List: React.FC<ListProps> = props => {
	const { items, toggle } = props;

	const [refs, setRefs] = useState([]);

	const setRefByIndex = (ref: React.MutableRefObject<HTMLElement>, index: number) => {
		refs[index] = ref;
		setRefs(refs);
	};

	const selectItem = (dropdownitem: TDropdownItem) => {
		const index = items.indexOf(dropdownitem);
		refs[index].current.focus();
	};

	const previousItem = (dropdownitem: TDropdownItem) => {
		const index = items.indexOf(dropdownitem);
		if (index > 0) {
			selectItem(items[index - 1]);
		} else {
			selectItem(items[items.length - 1]);
		}
	};

	const nextItem = (dropdownitem: TDropdownItem) => {
		const index = items.indexOf(dropdownitem);
		if (index < items.length - 1) {
			selectItem(items[index + 1]);
		} else {
			selectItem(items[0]);
		}
	};

	const handleKeydown = (
		event: React.KeyboardEvent<HTMLAnchorElement>,
		dropdownitem: TDropdownItem
	) => {
		if (event.which === 38) {
			event.preventDefault();
			previousItem(dropdownitem);
		} else if (event.which === 40) {
			event.preventDefault();
			nextItem(dropdownitem);
		}
	};

	useEffect(() => {
		refs[0].current.focus();
	}, [refs]);

	return (
		<ul className={classes(css.dropdownlist)}>
			{items.map((item: TDropdownItem, index: number) => (
				<DropdownItem
					key={index}
					onClick={() => {
						toggle && toggle.toggle();
					}}
					handleKeydown={handleKeydown}
					dropdownItem={item}
					index={index}
					setRefByIndex={setRefByIndex}
				></DropdownItem>
			))}
		</ul>
	);
};

type IDropdownListProps = RemoveProp<ListProps & DropdownProps, 'label'> & {
	ContentTop?: React.FC;
	ContentBottom?: React.FC;
	label?: string;
};

interface DropdownContentProps {
	ContentTop: React.FC;
	ContentBottom: React.FC;
	items: TDropdownItem[];
	toggle: TToggle;
}

const DropdownContent: React.FC<DropdownContentProps> = ({
	ContentTop,
	ContentBottom,
	items,
	toggle
}) => {
	return (
		<>
			{ContentTop && <ContentTop />}
			<List toggle={toggle} items={items}></List>
			{ContentBottom && <ContentBottom />}
		</>
	);
};

export const DropdownList: React.FC<IDropdownListProps> = props => {
	const { items, ContentTop, ContentBottom, label, toggle } = props;
	const dropdownProps = {
		...removePropertiesFromObjects(['items', 'ContentTop', 'ContentBottom'], props),
		label
	};

	return (
		<Dropdown {...dropdownProps}>
			<DropdownContent
				toggle={toggle}
				items={items}
				ContentTop={ContentTop}
				ContentBottom={ContentBottom}
			/>
		</Dropdown>
	);
};
