import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { ApiResponse, handleResponse } from './api';
import { User } from './auth';
import { forceFolderRefresh } from './inventory';
import { Audit, AuditLocation } from './audit';

// SxanPro API matching types
export type Organization = {
	id: string;
	name: string;
	active: boolean;
	active_orgdb_upload_id?: string;
	can_orgdb: boolean;
	orgdb_filename: string;
	field_toggles?: string[];
	product?: OrganizationProduct;
	root_folder_id?: number;
	has_audits: boolean;
	has_exchange_report: boolean;
	audit_expire_alert_days_default: number;
	inventory_expire_alert_days_default: number;
	sam_warn_on_duplicate: boolean;
	created_at: string;
	updated_at: string;
	deleted_at: string;
};
// above cuts out 'managers' (for now)

export type OrganizationUpdate = {
	id?: string;
	newId?: string;
	name: string;
	email: string;
	active_orgdb_upload_id?: string;
	can_orgdb?: boolean;
	orgdb_filename?: string;
	field_toggles?: string[];
	product?: OrganizationProduct;
	has_audits?: boolean;
	has_exchange_report?: boolean;
	audit_expire_alert_days_default?: number;
	inventory_expire_alert_days_default?: number;
	sam_warn_on_duplicate?: boolean;
	audit_export_fields?: string[];
	audit_location_templates?: AuditLocationTemplateUpdate[];
	exchange_report_configuration?: ExchangeReportConfiguration;
};

export type AuditLocationTemplateUpdate = {
	id?: string;
	parent_id?: string;
	organization_id: string;
	name: string;
	sort_order: number;
	deleted_at?: string;
};

export type ExchangeReportConfigurationFolder = {
	id: string;
	organization_id: string;
	folder_id: number;
	name: string;
	audits: Audit[];
};

export type ExchangeReportConfiguration = {
	id?: string;
	organization_id: string;
	wastage_enabled: boolean;
	donate_enabled: boolean;
	transfer_enabled: boolean;
	transfer_csc_enabled: boolean;
	sell_enabled: boolean;
	sell_usage_enabled: boolean;
	sell_expiration_enabled: boolean;
	sell_usage_below_quantity_enabled: boolean;
	wastage_max_days: number;
	wastage_max_price: number;
	donate_min_days: number;
	donate_max_days: number;
	donate_max_price: number;
	transfer_min_days: number;
	transfer_max_days: number;
	transfer_min_price: number;
	transfer_max_external_quantity_on_hand: number;
	transfer_min_external_usage: number;
	transfer_csc_min_days: number;
	transfer_csc_max_days: number;
	transfer_csc_min_price: number;
	sell_max_usage: number;
	sell_min_days: number;
	sell_max_days: number;
	audits: { [folder_id: string]: string };
};

// Product constants
export const PRODUCT_JUDI = 'judi';
export const PRODUCT_SAM = 'sam';
export type OrganizationProduct = typeof PRODUCT_SAM | typeof PRODUCT_JUDI;

export interface UserUpdate {
	id?: number;
	name: string;
	username: string;
	password: string;
	c_password: string;
	organization: string;
	role: string;
	root_folder_id?: number;
	email: string;
}

// Convenience types
type ApiOrganizationsResponse = { organizations: Organization[] };
type ApiOrganizationResponse = { organization: Organization };
type ApiUsersResponse = { users: User[] };
type ApiOrgDbResponse = {
	orgDB: {
		id: string;
		organization_id: string;
		upload_path: string;
		upload_filename: string;
		database_filename: string;
		updated_at: Date;
	}[];
};

type ApiOrgAuditConfigurationResponse = {
	audit_expire_alert_days_default: number;
	export_fields: string[];
	location_templates: AuditLocation[];
};
type ApiOrgExchangeReportConfigurationResponse = {
	exchange_report_configuration: ExchangeReportConfiguration;
};
type ApiOrgExchangeReportConfigurationFoldersResponse = {
	top_level_folders: ExchangeReportConfigurationFolder[];
};

// Gets the org list
export const useOrganizations = () => {
	const axios = useAxios();

	return useQuery(
		['organizations'],
		async () => {
			return handleResponse(axios.get<ApiResponse<ApiOrganizationsResponse>>(`/accounts`), false);
		},
		{
			select: useCallback((data: ApiOrganizationsResponse) => {
				return data.organizations;
			}, [])
		}
	);
};

// Single org info
export const useOrganization = (organizationId: string) => {
	const axios = useAxios();

	return useQuery(
		['organization', `${organizationId}`],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiOrganizationResponse>>(`/accounts/${organizationId}`),
				false
			);
		},
		{
			enabled: !!organizationId,
			select: useCallback((data: ApiOrganizationResponse) => {
				return data.organization;
			}, [])
		}
	);
};

export const useAddOrganization = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (organization: OrganizationUpdate) => {
			const response = await axios.post<
				Partial<Organization>,
				AxiosResponse<ApiResponse<{ organization: Organization }>>
			>('/accounts', organization);

			if (response.status === 200) {
				return {
					success: response.data.success,
					error: '',
					data: response.data.data.organization
				} as ApiResponse<Organization>;
			}
		},
		{
			// Make sure any cached list of this user's org users is refreshed
			onSuccess: () => {
				queryClient.invalidateQueries(['organizations']);
			}
		}
	);
};

export const useUpdateOrganization = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (organization: Partial<OrganizationUpdate>) => {
			const response = await axios.put<
				Partial<OrganizationUpdate>,
				AxiosResponse<ApiResponse<{ organization: Organization }>>
			>(`/accounts/${organization.id}`, organization);

			if (response.status === 200) {
				return {
					success: response.data.success,
					error: '',
					data: response.data.data.organization
				} as ApiResponse<Organization>;
			}
		},
		{
			// Make sure any cached list of this user's org users is refreshed
			onSuccess: (data, organization) => {
				queryClient.invalidateQueries(['organizations']);
				queryClient.invalidateQueries(['organization', `${organization.id}`]);
				queryClient.invalidateQueries(['auditConfiguration', `${organization.id}`]);
				queryClient.invalidateQueries(['exchangeReportConfig', `${organization.id}`]);
				queryClient.invalidateQueries(['exchangeReportConfigFolders', `${organization.id}`]);

				forceFolderRefresh(queryClient, data?.data?.root_folder_id, organization.id, -1, 0);
			}
		}
	);
};

// Gets a list of users in an organization
export const useOrganizationUsers = (organizationId: string) => {
	const axios = useAxios();

	// for now, this just uses the all users call and filters it since it does not
	// appear the api has a dedicated call for this
	return useQuery(
		['organizationUsers', `${organizationId}`],
		async () => {
			return handleResponse(axios.get<ApiResponse<ApiUsersResponse>>(`/users`), false);
		},
		{
			enabled: !!organizationId,

			select: useCallback(
				(data: ApiUsersResponse) => {
					return data.users.filter(
						user => user.deleted_at === null && user.organization_id === organizationId
					);
				},
				[organizationId]
			)
		}
	);
};

// Add / Update the given user
export const useAddUpdateUser = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (user: Partial<UserUpdate>) => {
			let response = null;
			if (user?.id) {
				response = await axios.put<
					Partial<UserUpdate>,
					AxiosResponse<ApiResponse<{ user: User }>>
				>(`/users/${user.id}`, user);
			} else {
				response = await axios.post<
					Partial<UserUpdate>,
					AxiosResponse<ApiResponse<{ user: User }>>
				>('/users', user);
			}

			if (response.status === 200) {
				return {
					success: response.data.success,
					error: '',
					data: response.data.data.user
				} as ApiResponse<User>;
			}
		},
		{
			// Make sure any cached list of this user's org users is refreshed
			onSuccess: (data, user) => {
				queryClient.invalidateQueries(['organizationUsers', `${user.organization}`]);
			}
		}
	);
};

export const useRemoveUser = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (user: User) => {
			await axios.delete(`/users/${user.id}`);
		},
		{
			onSuccess: (data, user) => {
				queryClient.invalidateQueries(['organizationUsers', `${user.organization_id}`]);
			}
		}
	);
};

export const useOrganizationDatabases = (organizationId: string) => {
	const axios = useAxios();

	return useQuery(
		['organizationDatabases', organizationId],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiOrgDbResponse>>(`/accounts/${organizationId}/orgDB`),
				false
			);
		},
		{
			enabled: !!organizationId,
			select: useCallback((data: ApiOrgDbResponse) => {
				return data.orgDB;
			}, [])
		}
	);
};

export const useOrganizationAuditConfiguration = (organizationId: string) => {
	const axios = useAxios();

	return useQuery(
		['auditConfiguration', organizationId],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiOrgAuditConfigurationResponse>>(
					`/accounts/${organizationId}/auditConfiguration`
				),
				false
			);
		},
		{
			enabled: !!organizationId,

			select: useCallback((data: ApiOrgAuditConfigurationResponse) => {
				return data;
			}, [])
		}
	);
};

export const useOrganizationExchangeReportConfigurationFolders = (organizationId?: string) => {
	const axios = useAxios();

	return useQuery(
		['exchangeReportConfigFolders', `${organizationId}`],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiOrgExchangeReportConfigurationFoldersResponse>>(
					`/accounts/${organizationId}/exchangeReportConfiguration/topLevelFolders`
				),
				false
			);
		},
		{
			enabled: typeof organizationId === 'string',
			select: useCallback((data: ApiOrgExchangeReportConfigurationFoldersResponse) => {
				return data.top_level_folders;
			}, [])
		}
	);
};

export const useOrganizationExchangeReportConfiguration = (organizationId?: string) => {
	const axios = useAxios();

	return useQuery(
		['exchangeReportConfig', `${organizationId}`],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiOrgExchangeReportConfigurationResponse>>(
					`/accounts/${organizationId}/exchangeReportConfiguration`
				),
				false
			);
		},
		{
			enabled: typeof organizationId === 'string',
			select: useCallback((data: ApiOrgExchangeReportConfigurationResponse) => {
				return data.exchange_report_configuration;
			}, [])
		}
	);
};
