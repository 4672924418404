import React, { createContext, useCallback, useContext, useState } from 'react';
import { useToggle } from 'common/hooks/useToggle';
import { Modal, ModalProps } from 'components/Modal';

export interface ModalProviderProps {
	createModal: <T>(modal: ModalProps<T>) => void;
	removeModal: () => void;
	setModalLoading: (isLoading: boolean) => void;
	setIsModalError: (isError: boolean) => void;
	isShown: boolean;
}

export const ModalContext = createContext<ModalProviderProps>(null);

const ModalProvider = ({ children }: React.PropsWithChildren<unknown>) => {
	const [modalProps, setModalProps] = useState<ModalProps>(null);
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [isModalError, setIsModalError] = useState(false);
	const toggle = useToggle(false);

	const removeModal = useCallback(() => {
		toggle.setIsShown(false);
		setModalProps(null);
		setIsModalError(false);
		setIsModalLoading(false);
	}, [setModalProps, toggle]);

	const createModal = useCallback(
		(props: any) => {
			toggle.setIsShown(true);
			setModalProps(props);
			toggle.setSourceEvent(props.sourceEvent);
		},
		[setModalProps, toggle]
	);

	const setModalLoading = useCallback(
		(isLoading: boolean) => {
			setIsModalLoading(isLoading);
		},
		[setIsModalLoading]
	);

	return (
		<ModalContext.Provider
			value={{
				createModal,
				removeModal,
				setModalLoading,
				setIsModalError,
				isShown: toggle.isShown
			}}
		>
			{modalProps && (
				<Modal
					{...modalProps}
					removeModal={removeModal}
					isShown={toggle.isShown}
					isModalLoading={isModalLoading}
					isModalError={isModalError}
					setModalRef={toggle.setRef}
					setModalLoading={setModalLoading}
					setIsModalError={setIsModalError}
				/>
			)}
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => {
	return useContext(ModalContext);
};

export default ModalProvider;
