import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { UserRole } from 'common/types/UserRole';
import { Spinner } from 'components/Spinner';
import { useCurrentUser } from 'api/auth';

interface IIsUserOfRole {
	ComponentToRender: React.FC;
	userRoles: UserRole[];
}

export const IsUserOfRoleRouteGuard: React.FC<IIsUserOfRole> = props => {
	const { data: user } = useCurrentUser();
	const { ComponentToRender, userRoles } = props;
	const history = useHistory();

	useEffect(() => {
		if (user?.role) {
			const containsUserRole = !!userRoles.find(role => {
				return role === user.role;
			});
			if (!containsUserRole) {
				history.push('/');
			}
		}
	}, [user, userRoles, history]);

	if (user?.role) {
		return <ComponentToRender />;
	} else {
		return (
			<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
		);
	}
};
