import React, { LegacyRef } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { ButtonTypes } from 'common/types/ButtonTypes';
import classes from 'classnames';
import css from './Link.module.scss';
import { ButtonSizes } from 'common/types/ButtonSizes';
import { removePropertiesFromObjects } from 'common/utils/removePropertiesFromObjects';
import { Colors } from 'common/types/Colors';

export type ILinkProps = {
	type?: ButtonTypes;
	size?: ButtonSizes;
	external?: boolean;
	focusRingColor?: Colors;
	classNames?: {
		buttonContent?: string;
		focusRing?: string;
	};
} & LinkProps;

export const Link = React.forwardRef((props: ILinkProps, ref?: LegacyRef<any>) => {
	const {
		children,
		className,
		type = 'link',
		size = 'medium',
		focusRingColor = 'blue',
		classNames,
		external = false
	} = props;

	const routerLinkProps = removePropertiesFromObjects(
		['classNames', 'type', 'size', 'children', 'external'],
		props
	);

	if (external) {
		return (
			<a
				href={props.to as string}
				className={classes(className, css[type], css.linkLocal, css[size])}
				ref={ref}
				rel='noopener noreferrer'
				target='_blank'
			>
				<span className={classes(css.buttonContent, classNames?.buttonContent)}>{children}</span>
				<div
					className={classes(css.focusRing, css[focusRingColor], classNames?.focusRing)}
				></div>
			</a>
		);
	}

	return (
		<RouterLink
			{...(routerLinkProps as any)}
			className={classes(className, css[type], css.linkLocal, css[size])}
			ref={ref}
		>
			<span className={classes(css.buttonContent, classNames?.buttonContent)}>{children}</span>
			<div className={classes(css.focusRing, css[focusRingColor], classNames?.focusRing)}></div>
		</RouterLink>
	);
});
