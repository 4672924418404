import { PRODUCT_SAM, PRODUCT_JUDI } from 'api/organization';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { ApiResponse, handleResponse } from './api';
import { OrganizationProduct } from './organization';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { useAuth } from 'providers/Auth/AuthProvider';
import { clone } from 'ramda';

// SxanPro API matching types
export interface User {
	id: number;
	name: string;
	username: string;
	email: string;
	organization_id: string;
	active: boolean;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	role: Role;
	root_folder_id?: number;
	has_audits: boolean;
	has_exchange_report: boolean;
	product?: OrganizationProduct;
	can_orgdb?: boolean;
	orgdb_filename?: string;
	field_toggles: string[];
}

export type Role = 'admin' | 'manager' | 'user';

export type LoginCredentials = {
	organization: string;
	username: string;
	password: string;
};

type ApiLoginResponse = {
	token: string;
	user: User;
};

export type LoginResult = {
	success: boolean;
	error?: string;
	user?: User;
	token?: string;
};

// Logs in a user with the given credentials
export const useLogin = () => {
	const axios = useAxios();

	return useMutation(async (credentials: LoginCredentials) => {
		const response = await axios.post<
			LoginCredentials,
			AxiosResponse<ApiResponse<ApiLoginResponse>>
		>('/login', credentials);

		if (response.status === 200) {
			return {
				success: response.data.success,
				error: '',
				user: determineOrganizationProduct(response.data.data.user),
				token: response.data.data.token
			} as LoginResult;
		}
	});
};

function isType<T>(value: T | ApiResponse<T>, doesHaveProp: string): value is T {
	return value.hasOwnProperty(doesHaveProp);
}

// Gets information about the given user
export const useUserById = (userId: string) => {
	const axios = useAxios();

	return useQuery(['user', userId], async () => {
		const result = await handleResponse(
			axios.get<ApiResponse<{ user: User }>>(`/users/${userId}`),
			false
		);
		if (isType(result, 'user')) {
			return determineOrganizationProduct(result?.user);
		}
	});
};

// Gets information about the currently logged in user
export const useCurrentUser = () => {
	const { userId } = useAuth();

	return useUserById(userId);
};

// Gets a list of users the current user can see
export const useUsers = () => {
	const axios = useAxios();

	return useQuery(['users'], async () => {
		const response = await handleResponse(
			axios.get<ApiResponse<{ users: User[] }>>(`/users`),
			false
		);
		return response?.users;
	});
};

const determineOrganizationProduct = (user: User) => {
	const newUser = clone(user);

	if (!newUser.product) {
		newUser.product = newUser.can_orgdb ? PRODUCT_SAM : PRODUCT_JUDI;
	}

	return newUser;
};

export const canUseAudits = (user: User) => {
	return user.role === 'admin' || (user.role === 'manager' && user.has_audits);
};
