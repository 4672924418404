import React, { Dispatch, useRef, useEffect, useState, ReactElement } from 'react';
import css from './Modal.module.scss';
import classes from 'classnames';
import { Container } from 'components/Container';
import ReactDOM from 'react-dom';
import { Button } from 'components/Button';
import { Alert } from 'components/Alert';
import { StyleProps } from 'common/types/StyleProps';
import { ButtonTypes } from 'common/types/ButtonTypes';
import 'wicg-inert';
import { disableBodyScroll, enableBodyScroll } from 'common/utils/bodyScrollLock';

import { ModalProviderProps } from 'providers/Modal/ModalProvider';

export interface ModalProps<T = void> extends StyleProps {
	heading: string;
	hasPrimaryButton?: boolean;
	primaryButtonAction?: (externallyAvailableState: T) => void;
	primaryButtonLabel?: string;
	primaryButtonType?: ButtonTypes;
	hasSecondaryButton?: boolean;
	secondaryButtonAction?: () => void;
	secondaryButtonLabel?: string;
	secondaryButtonType?: ButtonTypes;
	isShown?: boolean;
	isModalLoading?: boolean;
	modalLoadingLabel?: string;
	isModalError?: boolean;
	modalErrorContent?: string;
	sourceEvent?: any;
	decription?: React.ReactNode;
	content: ReactElement;
	setModalRef?: Dispatch<any>;
	removeModal?: ModalProviderProps['removeModal'];
	setModalLoading?: ModalProviderProps['setModalLoading'];
	setIsModalError?: ModalProviderProps['setIsModalError'];
}

export interface ModalContentProps<T> {
	externallyAvailableState?: T;
	setExternallyAvailableState?: React.Dispatch<T>;
	removeModal?: ModalProviderProps['removeModal'];
	setModalLoading?: ModalProviderProps['setModalLoading'];
	setIsModalError?: ModalProviderProps['setIsModalError'];
}

export const Modal: React.FC<ModalProps> = props => {
	const {
		content,
		className,
		heading,
		removeModal,
		hasPrimaryButton,
		primaryButtonType = 'primary',
		primaryButtonAction = () => removeModal(),
		primaryButtonLabel = 'Ok',
		hasSecondaryButton = true,
		secondaryButtonAction = () => removeModal(),
		secondaryButtonLabel = 'Close',
		secondaryButtonType = 'secondary',
		isShown = false,
		isModalLoading = false,
		modalLoadingLabel = 'Loading',
		isModalError = false,
		modalErrorContent = 'An error occurred please reload the page and try again.',
		setModalRef,
		decription,
		setModalLoading,
		setIsModalError
	} = props;

	const modalRef = useRef(null);
	const modalDescriptionRef = useRef(null);
	const backdropRef = useRef(null);
	const [externallyAvailableState, setExternallyAvailableState] = useState<any>();

	useEffect(() => {
		setModalRef(modalRef);

		if (isShown) {
			modalDescriptionRef.current.focus();
		} else {
			removeModal();
		}
		// eslint-disable-next-line
	}, [isShown, modalDescriptionRef, modalRef]);

	useEffect(() => {
		const rootElement = document.querySelector('#root');
		const backdropRefCurrent = backdropRef.current;
		disableBodyScroll(backdropRefCurrent);
		// @ts-ignore
		rootElement.inert = true;
		return () => {
			const rootElement = document.querySelector('#root');
			// @ts-ignore
			rootElement.inert = false;
			enableBodyScroll(backdropRefCurrent);
		};
	}, []);

	return ReactDOM.createPortal(
		<div className={css.backdrop} ref={backdropRef}>
			<Container>
				<div
					className={classes(css.modal, className)}
					onClick={event => event.stopPropagation()}
					ref={modalRef}
					role='dialog'
					aria-labelledby='modalHeading'
					aria-describedby='modalDescription'
				>
					<div id='modalDescription' className='screenReaderText'>
						Beginning of dialog window. Escape will cancel and close the window.
					</div>
					<div className={css.header}>
						<h2
							id='modalHeading'
							className={classes('h4', css.headerTitle)}
							tabIndex={-1}
							ref={modalDescriptionRef}
						>
							{heading}
						</h2>
						<Button
							tooltipMessage='Close Modal'
							onClick={() => removeModal()}
							iconLeft={{
								type: 'Close',
								color: 'black',
								size: 'large'
							}}
							type='control'
							size='smallest'
						></Button>
					</div>
					<div className={css.inner}>
						{isModalError && <Alert type='danger-light'>{modalErrorContent}</Alert>}
						{decription && <p className={css.description}>{decription}</p>}
						{React.cloneElement(content, {
							externallyAvailableState,
							setExternallyAvailableState,
							removeModal,
							setModalLoading,
							setIsModalError
						})}
					</div>
					<div className={css.footer}>
						{hasPrimaryButton && (
							<Button
								type={primaryButtonType}
								isLoading={isModalLoading}
								loadingLabel={modalLoadingLabel}
								onClick={() => primaryButtonAction(externallyAvailableState)}
							>
								{primaryButtonLabel}
							</Button>
						)}
						{hasSecondaryButton && (
							<Button
								type={secondaryButtonType}
								className={css.secondaryButton}
								onClick={() => secondaryButtonAction()}
							>
								{secondaryButtonLabel}
							</Button>
						)}
					</div>
				</div>
			</Container>
		</div>,
		document.body
	);
};
