import css from './BottomMenuContent.module.scss';
import { Icon } from 'components/Icon';

export const BottomMenuContent = () => {
	return (
		<div className={css.bottomMenuContent}>
			<h2 className={css.title}>Need Immediate Help?</h2>
			<p className={css.description}>Send us a text or email at</p>
			<ul>
				<li>
					<Icon type='Phone' className={css.icon} color='black' />
					616-574-2605
				</li>
				<li>
					<Icon type='Email' className={css.icon} color='black' />
					<a href='mailto:info@sxanpro.com'>info@sxanpro.com</a>
				</li>
			</ul>
		</div>
	);
};
