import React from 'react';
import css from './Badge.module.scss';
import classes from 'classnames';

export interface BadgeProps {
	className?: string;
}

export const Badge: React.FC<BadgeProps> = props => {
	const { className, children } = props;
	return <span className={classes(className, css.badge)}>{children}</span>;
};
