import { NavLink } from 'react-router-dom';
import { Icon, IconTypes } from 'components/Icon';
import css from './SidebarLink.module.scss';
import { Button } from 'components/Button';
import classes from 'classnames';

interface SidebarLinkProps {
	title: string;
	to: (() => void) | string;
	icon?: IconTypes;
	exact?: boolean;
	isVisibleOnDesktop?: boolean;
}

export const SidebarLink = ({
	title,
	icon,
	to,
	exact = false,
	isVisibleOnDesktop = true
}: SidebarLinkProps) => {
	if (typeof to === 'string') {
		return (
			<NavLink
				to={to}
				className={classes({ [css.isVisibleOnDesktop]: isVisibleOnDesktop }, css.navItem)}
				activeClassName={css.activeItem}
				exact={exact}
			>
				{icon && <Icon type={icon} size='small' className={css.icon} color='black' />}
				<div className={css.title}>{title}</div>
			</NavLink>
		);
	}

	if (typeof to === 'function') {
		return (
			<Button
				type='sidebarLink'
				size='small'
				className={classes({ [css.isVisibleOnDesktop]: isVisibleOnDesktop }, css.navItem)}
				classNames={{ buttonContent: css.functionLinkContent }}
				iconLeft={{
					type: icon,
					size: 'small',
					marginSize: 'right-large',
					className: 'functionOne!'
				}}
				onClick={() => {
					to();
				}}
			>
				{title}
			</Button>
		);
	}
};
