import React, { createContext, useContext, useState } from 'react';
import { IdType } from 'common/types/IdType';
import { reportingPageId } from 'views/ReportingView/ReportViewsContent';
import { RemovedFilterOptions } from 'api/reporting';

export type ExpiresInDays = '' | '0' | '30' | '60' | '90' | '182' | '365';

interface ReportingState {
	[id: string]: {
		organizations: string[];
		expiresInDays: string;
		lists: IdType[];
		manufacturers: string[];
		referenceNumbers: string[];
		fdaProductCodes: string[];
		dontShowExpired: boolean;
		removedFilter: RemovedFilterOptions;
		search: string;
	};
}

type ReportingStateValues = ReportingState['id'][keyof ReportingState['id']];

interface ReportingProviderProps {
	setReportingState: (
		pageId: reportingPageId,
		propertyToUpdate: keyof ReportingState['id']
	) => (value: ReportingStateValues) => void;

	reportingState: {
		(pageId: reportingPageId, propertyToAccess: 'organizations'): string[];
		(pageId: reportingPageId, propertyToAccess: 'expiresInDays'): ExpiresInDays;
		(pageId: reportingPageId, propertyToAccess: 'lists'): IdType[];
		(pageId: reportingPageId, propertyToAccess: 'manufacturers'): string[];
		(pageId: reportingPageId, propertyToAccess: 'referenceNumbers'): string[];
		(pageId: reportingPageId, propertyToAccess: 'fdaProductCodes'): string[];
		(pageId: reportingPageId, propertyToAccess: 'dontShowExpired'): boolean;
		(pageId: reportingPageId, propertyToAccess: 'removedFilter'): RemovedFilterOptions;
		(pageId: reportingPageId, propertyToAccess: 'search'): string;
	};
}

export const ReportingContext = createContext<ReportingProviderProps>(null);

export const ReportingProvider = ({ children }: React.PropsWithChildren<unknown>) => {
	const [providersReportingState, setProvidersReportingState] = useState<ReportingState>({});

	const setReportingState = (
		pageId: reportingPageId,
		propertyToUpdate: keyof ReportingState['id']
	) => {
		return (value: ReportingStateValues) => {
			const currentReportingPageState: any = { ...providersReportingState[pageId] };
			currentReportingPageState[propertyToUpdate] = value;

			// If organizations is changed, clear the lists
			if (propertyToUpdate === 'organizations') {
				currentReportingPageState.lists = [];
			}

			// If organizations or lists change, clear the contextual filter selections
			if (propertyToUpdate === 'organizations' || propertyToUpdate === 'lists') {
				currentReportingPageState.manufacturers = [];
				currentReportingPageState.referenceNumbers = [];
				currentReportingPageState.fdaProductCodes = [];
			}

			setProvidersReportingState({
				...providersReportingState,
				[pageId]: {
					...currentReportingPageState
				}
			});
		};
	};

	function reportingState(pageId: reportingPageId, propertyToAccess: 'organizations'): string[];
	function reportingState(pageId: reportingPageId, propertyToAccess: 'expiresInDays'): ExpiresInDays;
	function reportingState(pageId: reportingPageId, propertyToAccess: 'lists'): IdType[];
	function reportingState(pageId: reportingPageId, propertyToAccess: 'manufacturers'): string[];
	function reportingState(pageId: reportingPageId, propertyToAccess: 'referenceNumbers'): string[];
	function reportingState(pageId: reportingPageId, propertyToAccess: 'fdaProductCodes'): string[];
	function reportingState(pageId: reportingPageId, propertyToAccess: 'dontShowExpired'): boolean;
	function reportingState(
		pageId: reportingPageId,
		propertyToAccess: 'removedFilter'
	): RemovedFilterOptions;
	function reportingState(pageId: reportingPageId, propertyToAccess: 'search'): string;

	function reportingState(
		pageId: reportingPageId,
		propertyToAccess: keyof ReportingState['id']
	): ReportingStateValues {
		const defaultState: ReportingState['id'] = {
			organizations: [],
			expiresInDays: pageId === 'UpcomingExpiration' ? '60' : '',
			lists: [],
			manufacturers: [],
			referenceNumbers: [],
			fdaProductCodes: [],
			dontShowExpired: false,
			removedFilter: 'all',
			search: ''
		};

		if (typeof providersReportingState?.[pageId]?.[propertyToAccess] !== 'undefined') {
			return providersReportingState?.[pageId]?.[propertyToAccess];
		} else {
			return defaultState[propertyToAccess];
		}
	}

	return (
		<ReportingContext.Provider
			value={{
				setReportingState,
				reportingState
			}}
		>
			{children}
		</ReportingContext.Provider>
	);
};

export const useReportingState = () => {
	return useContext(ReportingContext);
};
