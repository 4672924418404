import { useCallback } from 'react';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	ApiResponse,
	handleResponse,
	BlobResponse,
	useAxiosRawRequest,
	EXPORT_DEFAULT_FORMAT,
	EXPORT_TIMEOUT,
	ApiPagingSortingOptions
} from './api';
import { Device } from 'api/inventory';
import { AxiosResponse } from 'axios';
import { Folders } from 'components/DropdownInventory';
import { format } from 'date-fns';
import { IdType } from 'common/types/IdType';
import { ExportFormat } from 'common/types/ExportFormat';
import { User, useCurrentUser } from './auth';
import { ExchangeReport, ExchangeReportInput } from './exchangeTool';

interface ReportingResponse {
	devices: Device[];
	total: number;
}

interface ApiFoldersResponse {
	folders: Folders;
}

interface ApiMetaResponse {
	company_name: string[];
	reference_number: string[];
	fda_product_code: string[];
}

interface ExpiringItemMonth {
	expirationYear: number;
	expirationMonth: number;
	count: number;
}

export type ApiExpiringItemsByMonthResponse = ExpiringItemMonth[];

interface ManufacturerItem {
	company_name: string;
	count: number;
}

export type ApiExpiringItemsByManufacturersResponse = ManufacturerItem[];

export interface ApiSamAssetOverviewReportInput {
	organization?: string;
	lists?: IdType[];
	match?: string;
	sortBy?: string;
	sortByDirection?: 'asc' | 'desc';
	search?: string;
	count?: number;
	page?: number;
}

export interface ApiOrgDbScanReportResponse {
	facility_product_id: string;
	quantity?: number;
	is_match: boolean;
}

export type ApiSamAssetsByManufacturerResponse = ManufacturerItem[];

export interface ApiSamAssetCountsResponse {
	matched_count: number;
	unmatched_count: number;
	total: number;
}

export type SavedReportType = 'exchange';

export interface SavedReport {
	id: number;
	organization_id: string;
	created_by: User;
	name: string;
	report_type: SavedReportType;
	created_at: string;
	updated_at: string;
	deleted_at?: string;
}

export type ApiSavedReportResponse = { report: SavedReport };
export type ApiSavedReportsResponse = { reports: SavedReport[] };

interface ApiSavedExchangeReportDataInput extends ApiPagingSortingOptions {
	locations?: string[];
	expiresInDays?: string;
	usage12Months?: number[];
	manufacturers?: string[];
	search?: string;
	dollarValues?: string[];
	action?: string[];
}

interface ApiSavedExchangeReportMetaResponse {
	usage_12months: string[];
	company_name: string[];
	action: {
		action: string;
		transfer_to_folder_id?: string;
		transfer_to_folder_name?: string;
	}[];
	action_taken: {
		action_taken: string;
		action_taken_transfer_to_folder_id?: string;
		action_taken_transfer_to_folder_name?: string;
	}[];
}

interface ApiFilterInput {
	organizations?: string[];
	lists?: IdType[];
}

export type RemovedFilterOptions = 'removed' | 'not_removed' | 'all';

export interface ApiReportingInput {
	lists?: IdType[];
	manufacturers?: string[];
	referenceNumbers?: string[];
	fdaProductCodes?: string[];
	sortBy?: string;
	sortByDirection?: 'asc' | 'desc';
	organizations?: string[];
	dontShowExpired?: boolean;
	expiresInDays?: string;
	removedFilter?: RemovedFilterOptions;
	search?: string;
	count?: number;
	page?: number;
	showOnlyExpired?: boolean;
}

export const useReporting = (options: {
	lists?: IdType[];
	manufacturers?: string[];
	referenceNumbers?: string[];
	fdaProductCodes?: string[];
	showExpired?: boolean;
	count?: number;
	page?: number;
	sortBy?: string;
	sortByDirection?: 'asc' | 'desc';
	organizations?: string[];
	dontShowExpired?: boolean;
	expiresInDays?: string;
	removedFilter?: RemovedFilterOptions;
	search?: string;
	showOnlyExpired?: boolean;
}) => {
	const axios = useAxios();

	const {
		lists,
		manufacturers,
		referenceNumbers,
		fdaProductCodes,
		showExpired,
		count,
		page,
		sortBy,
		sortByDirection,
		organizations,
		dontShowExpired,
		expiresInDays,
		removedFilter,
		search,
		showOnlyExpired
	} = options;

	return useQuery(
		[
			'reporting',
			lists,
			manufacturers,
			referenceNumbers,
			fdaProductCodes,
			showExpired,
			count,
			page,
			sortBy,
			sortByDirection,
			organizations,
			dontShowExpired,
			expiresInDays,
			removedFilter,
			search,
			showOnlyExpired
		],
		async () => {
			return handleResponse(
				axios.post<ApiReportingInput, AxiosResponse<ApiResponse<ReportingResponse>>>(
					`/reporting/deviceReport`,
					{
						lists,
						manufacturers,
						referenceNumbers,
						fdaProductCodes,
						sortBy,
						sortByDirection,
						organizations,
						dontShowExpired,
						expiresInDays,
						removedFilter,
						search,
						count,
						page,
						showOnlyExpired
					}
				),
				true
			);
		},
		{
			select: useCallback((data: ApiResponse<ReportingResponse>) => {
				return data;
			}, []),
			keepPreviousData: true
		}
	);
};

export const useFolder = (organizations?: string[]) => {
	const axios = useAxios();

	return useQuery(
		['folder', organizations],
		async () => {
			return handleResponse(
				axios.post<ApiFilterInput, AxiosResponse<ApiResponse<ApiFoldersResponse>>>(
					`/folders/tree`,
					{ organizations }
				),
				false
			);
		},
		{
			// The select will help transform the data coming form the api
			select: useCallback((data: ApiFoldersResponse): Folders => {
				return data.folders;
			}, [])
		}
	);
};

export const useMeta = (organizations?: string[], lists?: IdType[]) => {
	const axios = useAxios();

	return useQuery(
		['meta', organizations, lists],
		async () => {
			return handleResponse(
				axios.post<ApiFilterInput, AxiosResponse<ApiResponse<ApiMetaResponse>>>(
					`/reporting/deviceReport/meta`,
					{ organizations, lists }
				),
				false
			);
		},
		{
			// The select will help transform the data coming form the api
			select: useCallback((data: ApiMetaResponse): ApiMetaResponse => {
				return data;
			}, [])
		}
	);
};

export const useExportReport = (options: {
	lists?: IdType[];
	manufacturers?: string[];
	referenceNumbers?: string[];
	fdaProductCodes?: string[];
	showExpired?: boolean;
	sortBy?: string;
	sortByDirection?: 'asc' | 'desc';
	organizations: string[];
	dontShowExpired: boolean;
	expiresInDays: string;
	removedFilter?: RemovedFilterOptions;
	search: string;
	showOnlyExpired: boolean;
}) => {
	const axios = useAxios();

	const {
		lists,
		manufacturers,
		referenceNumbers,
		fdaProductCodes,
		sortBy,
		sortByDirection,
		organizations,
		dontShowExpired,
		expiresInDays,
		removedFilter,
		search,
		showOnlyExpired
	} = options;

	return useAxiosRawRequest<BlobResponse, unknown, [ExportFormat?]>(
		(exportFormat: ExportFormat = EXPORT_DEFAULT_FORMAT) => {
			return axios.post(
				`/reporting/deviceReport/export?format=${exportFormat}`,
				{
					lists,
					manufacturers,
					referenceNumbers,
					fdaProductCodes,
					sortBy,
					sortByDirection,
					organizations,
					dontShowExpired,
					expiresInDays,
					removedFilter,
					search,
					showOnlyExpired
				},
				{
					responseType: 'blob',
					timeout: EXPORT_TIMEOUT
				}
			);
		},
		{
			success: response => {
				const responseContentType = response.headers['content-type'];
				const extension = responseContentType?.includes('sheet') ? '.xlsx' : '.csv';

				const suggestedFilename = `SxanPro_Report_export_${format(
					new Date(),
					'yyyy_MM_dd_HH_mm_ss'
				)}${extension}`;

				return {
					blob: response.data as Blob,
					suggestedFilename
				};
			}
		}
	);
};

export const useExpiringItemsByMonth = () => {
	const axios = useAxios();

	return useQuery(
		['expiringItemsByMonth'],
		async () => {
			return handleResponse(
				axios.post<ApiResponse<ApiExpiringItemsByMonthResponse>>(
					`/reporting/expiringItemsByMonth`
				),
				false
			);
		},
		{
			// The select will help transform the data coming form the api
			select: useCallback(
				(data: ApiExpiringItemsByMonthResponse): ApiExpiringItemsByMonthResponse => {
					return data;
				},
				[]
			)
		}
	);
};

export const useExpiringItemsByManufacturer = () => {
	const axios = useAxios();

	return useQuery(
		['expiringItemsByManufacturers'],
		async () => {
			return handleResponse(
				axios.post<ApiResponse<ApiExpiringItemsByManufacturersResponse>>(
					`/reporting/expiringItemsByManufacturer`
				),
				false
			);
		},
		{
			// The select will help transform the data coming form the api
			select: useCallback(
				(
					data: ApiExpiringItemsByManufacturersResponse
				): ApiExpiringItemsByManufacturersResponse => {
					return data;
				},
				[]
			)
		}
	);
};

export const useSamAssetOverviewReport = (input: ApiSamAssetOverviewReportInput) => {
	const axios = useAxios();

	return useQuery(
		['samAssetOverviewReport', ...Object.values(input)],
		async () => {
			return handleResponse(
				axios.post<
					ApiSamAssetOverviewReportInput,
					AxiosResponse<ApiResponse<ApiOrgDbScanReportResponse>>
				>(`/reporting/orgDBScanReport`, input),
				true
			);
		},
		{
			enabled: input.organization?.length > 0,
			select: useCallback((data: ApiResponse<ApiOrgDbScanReportResponse>) => {
				return data;
			}, []),
			keepPreviousData: true
		}
	);
};

export const useSamAssetOverviewReportExport = (input: ApiSamAssetOverviewReportInput) => {
	const axios = useAxios();

	return useAxiosRawRequest<BlobResponse, unknown, [ExportFormat?]>(
		(exportFormat: ExportFormat = EXPORT_DEFAULT_FORMAT) => {
			return axios.post(`/reporting/orgDBScanReport/export?format=${exportFormat}`, input, {
				responseType: 'blob',
				timeout: EXPORT_TIMEOUT
			});
		},
		{
			success: response => {
				const responseContentType = response.headers['content-type'];
				const extension = responseContentType?.includes('sheet') ? '.xlsx' : '.csv';

				const suggestedFilename = `SxanPro_SAM_Usage_Report_Export_${format(
					new Date(),
					'yyyy_MM_dd_HH_mm_ss'
				)}${extension}`;

				return {
					blob: response.data as Blob,
					suggestedFilename
				};
			}
		}
	);
};

export const useSamAssetsByManufacturer = (input: ApiSamAssetOverviewReportInput) => {
	const axios = useAxios();

	return useQuery(
		['samAssetsByManufacturer', ...Object.values(input)],
		async () => {
			return handleResponse(
				axios.post<
					ApiSamAssetOverviewReportInput,
					AxiosResponse<ApiResponse<ApiSamAssetsByManufacturerResponse>>
				>(`/reporting/orgDBScansByManufacturer`, input),
				true
			);
		},
		{
			enabled: input.organization?.length > 0,
			select: useCallback((data: ApiResponse<ApiSamAssetsByManufacturerResponse>) => {
				return data;
			}, []),
			keepPreviousData: true
		}
	);
};

export const useSamAssetCounts = (input: ApiSamAssetOverviewReportInput) => {
	const axios = useAxios();

	return useQuery(
		['samAssetCounts', ...Object.values(input)],
		async () => {
			return handleResponse(
				axios.post<
					ApiSamAssetOverviewReportInput,
					AxiosResponse<ApiResponse<ApiSamAssetCountsResponse>>
				>(`/reporting/orgDBScanCounts`, input),
				true
			);
		},
		{
			enabled: input.organization?.length > 0,
			select: useCallback((data: ApiResponse<ApiSamAssetCountsResponse>) => {
				return data;
			}, []),
			keepPreviousData: true
		}
	);
};

// Saved Reports
export const useSavedReports = () => {
	const axios = useAxios();

	return useQuery(
		['savedReports'],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiSavedReportsResponse>>(`/savedReports`),
				false
			);
		},
		{
			select: useCallback((data: ApiSavedReportsResponse) => {
				return data.reports;
			}, [])
		}
	);
};

interface SavedReportInput<T> {
	name: string;
	report_type: SavedReportType;
	options?: T;
}

export const useSaveReport = <T>() => {
	const axios = useAxios();

	return useMutation(
		async (reportInput: SavedReportInput<T>) => {
			const { options, ...input } = reportInput;
			const response = await axios.post<any, AxiosResponse<ApiResponse<unknown>>>(
				'/savedReports',
				{
					...input,
					...options
				}
			);

			if (response.status === 200) {
				return {
					success: true,
					error: ''
				} as ApiResponse<unknown>;
			}
		},
		{
			onSuccess: (data, reportInput) => {}
		}
	);
};

export const useSavedReportExport = (savedReportId: string, options: any) => {
	const axios = useAxios();

	return useAxiosRawRequest<BlobResponse, unknown, [ExportFormat?]>(
		(exportFormat: ExportFormat = EXPORT_DEFAULT_FORMAT) => {
			return axios.post(`/savedReports/${savedReportId}/export?format=${exportFormat}`, options, {
				responseType: 'blob',
				timeout: EXPORT_TIMEOUT
			});
		},
		{
			success: response => {
				const responseContentType = response.headers['content-type'];
				const extension = responseContentType?.includes('sheet') ? '.xlsx' : '.csv';

				const suggestedFilename = `SxanPro_Saved_Report_Export_${format(
					new Date(),
					'yyyy_MM_dd_HH_mm_ss'
				)}${extension}`;

				return {
					blob: response.data as Blob,
					suggestedFilename
				};
			}
		}
	);
};

export const useDeleteSavedReport = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (savedReportId: string) => {
			await axios.delete(`savedReports/${savedReportId}`);
		},
		{
			onSuccess: (data, input) => {
				queryClient.invalidateQueries(['savedReports']);
			}
		}
	);
};

export const useSavedExchangeReport = (savedReportId: string) => {
	const axios = useAxios();

	return useQuery(
		['savedExchangeReport', savedReportId],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiSavedReportResponse>>(`savedReports/${savedReportId}`),
				false
			);
		},
		{
			enabled: typeof savedReportId === 'string',
			select: useCallback((data: ApiSavedReportResponse) => {
				return data.report;
			}, []),
			keepPreviousData: true
		}
	);
};

// TODO fix the any here
export const useSavedExchangeReportData = (
	savedReportId: string,
	options?: ApiSavedExchangeReportDataInput
) => {
	const axios = useAxios();

	return useQuery(
		['savedExchangeReportData', savedReportId, ...Object.values(options)],
		async () => {
			return handleResponse(
				axios.post<ApiSavedExchangeReportDataInput, AxiosResponse<ApiResponse<any>>>(
					`/savedReports/${savedReportId}/data`,
					{
						...options
					}
				),
				true
			);
		},
		{
			enabled: typeof savedReportId === 'string',
			select: useCallback((data: ApiResponse<any>) => {
				return data;
			}, []),
			keepPreviousData: true
		}
	);
};

export const useSavedExchangeReportMeta = (savedReportId: string) => {
	const axios = useAxios();

	return useQuery(
		['savedExchangeReportMeta', savedReportId],
		async () => {
			return handleResponse(
				axios.post<any, AxiosResponse<ApiResponse<ApiSavedExchangeReportMetaResponse>>>(
					`/savedReports/${savedReportId}/meta`,
					{}
				),
				false
			);
		},
		{
			enabled: savedReportId?.length > 0,
			select: useCallback(
				(data: ApiSavedExchangeReportMetaResponse): ApiSavedExchangeReportMetaResponse => {
					return data;
				},
				[]
			)
		}
	);
};
