import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes/Routes';

// React Query
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './config/queryProvider';

import AuthProvider from 'providers/Auth/AuthProvider';
import AxiosProvider from 'providers/Axios/AxiosProvider';

import './App.scss';
import ModalProvider from 'providers/Modal/ModalProvider';
import { ReportingProvider } from 'providers/Reporting/ReportingProvider';
import { HelmetProvider } from 'react-helmet-async';

function App() {
	return (
		<AxiosProvider>
			<HelmetProvider>
				<QueryClientProvider client={queryClient}>
					<ReportingProvider>
						<BrowserRouter>
							<ModalProvider>
								<AuthProvider>{Routes}</AuthProvider>
							</ModalProvider>
						</BrowserRouter>
					</ReportingProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</HelmetProvider>
		</AxiosProvider>
	);
}

export const handleFirstTab = (event: KeyboardEvent) => {
	if (event.keyCode === 9) {
		// the "I am a keyboard user" key
		document.body.classList.add('user-is-tabbing');
		window.removeEventListener('keydown', handleFirstTab);
	}
};

window.addEventListener('keydown', handleFirstTab);

export default App;
