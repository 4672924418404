import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppFrame } from 'components/AppFrame';
import * as Views from 'views/views';
import { PrivateRoute } from './RouteGaurds/PrivateRoute';
import { PublicRoute } from './RouteGaurds/PublicRoute';
import { IsUserOfRoleRouteGuard } from './RouteGaurds/IsUserOfRoleRouteGuard';
import { OrgHasAuditsRouteGuard } from './RouteGaurds/OrgHasAuditsRouteGuard';
import { LoadingDelay } from 'components/LoadingDelay';
import { Spinner } from 'components/Spinner';
import SamReportingView from '../views/SamReportingView/SamReportingView';

const RouteSuspense = ({ children }: { children: React.ReactNode }) => {
	return (
		<Suspense
			fallback={
				<LoadingDelay>
					<Spinner
						type='darker'
						size='large'
						isCenteredHorizontally
						isCenteredVertically
					></Spinner>
				</LoadingDelay>
			}
		>
			{children}
		</Suspense>
	);
};

export const Routes = (
	<Switch>
		<PublicRoute exact path='/login'>
			<Route exact path='/login'>
				<RouteSuspense>
					<Views.Authentication.LoginView />
				</RouteSuspense>
			</Route>
		</PublicRoute>
		<PrivateRoute path='/'>
			<AppFrame>
				<Switch>
					<Route exact path='/'>
						<RouteSuspense>
							<Views.DashboardView />
						</RouteSuspense>
					</Route>
					<Route exact path='/inventory'>
						<RouteSuspense>
							<Views.Inventory.FolderView />
						</RouteSuspense>
					</Route>
					<Route exact path='/inventory/folder/:folderId'>
						<RouteSuspense>
							<Views.Inventory.FolderView />
						</RouteSuspense>
					</Route>
					<Route exact path='/inventory/list/:listId'>
						<RouteSuspense>
							<Views.Inventory.ListView />
						</RouteSuspense>
					</Route>
					<Route path='/inventory/device/:deviceId'>
						<RouteSuspense>
							<Views.Inventory.DeviceView />
						</RouteSuspense>
					</Route>
					<Route exact path='/inventory/deleted'>
						<RouteSuspense>
							<Views.Inventory.DeletedInventoryView />
						</RouteSuspense>
					</Route>
					<Route exact path='/reporting'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.Reporting.ReportingView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/reporting/upcoming-expiration'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.Reporting.UpcomingExpirationReportView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/reporting/inventory-overview'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.Reporting.InventoryOverviewReportView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/reporting-sam'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.SamReporting.SamReportingView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/reporting-sam/sam-asset-overview-report'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.SamReporting.SamAssetOverviewReportView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/organizations'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.OrganizationManagementView}
								userRoles={['admin']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/organizations/create'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.OrganizationCreateView}
								userRoles={['admin']}
							/>
						</RouteSuspense>
					</Route>
					<Route path='/organizations/:organizationId'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.OrganizationDetailView}
								userRoles={['admin']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/link'>
						<RouteSuspense>
							<OrgHasAuditsRouteGuard ComponentToRender={Views.Audits.AuditSelectorView} />
						</RouteSuspense>
					</Route>
					<Route exact path='/link/folder/:folderId'>
						<RouteSuspense>
							<OrgHasAuditsRouteGuard ComponentToRender={Views.Audits.AuditSelectorView} />
						</RouteSuspense>
					</Route>
					<Route exact path='/link/create'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.Audits.AuditCreateEditView}
								userRoles={['admin']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/link/:auditId/edit'>
						<RouteSuspense>
							<OrgHasAuditsRouteGuard
								ComponentToRender={Views.Audits.AuditCreateEditView}
							/>
						</RouteSuspense>
					</Route>
					<Route path='/link/:auditId'>
						<RouteSuspense>
							<OrgHasAuditsRouteGuard ComponentToRender={Views.Audits.AuditView} />
						</RouteSuspense>
					</Route>
					<Route exact path='/saved-reports/exchange/:savedReportId'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.SavedReports.SavedExchangeReportView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
					<Route path='/saved-reports/:reportType'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.SavedReports.SavedReportsView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
					<Route exact path='/exchange'>
						<RouteSuspense>
							<OrgHasAuditsRouteGuard
								ComponentToRender={Views.ExchangeTool.ExchangeToolReportView}
							/>
						</RouteSuspense>
					</Route>
					<Route path='/saved-reports'>
						<RouteSuspense>
							<IsUserOfRoleRouteGuard
								ComponentToRender={Views.SavedReports.SavedReportsView}
								userRoles={['admin', 'manager']}
							/>
						</RouteSuspense>
					</Route>
				</Switch>
			</AppFrame>
		</PrivateRoute>
	</Switch>
);
