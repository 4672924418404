import React, { createContext, useContext, useMemo } from 'react';
import Axios, { AxiosInstance } from 'axios';
import { LOCAL_STORAGE_TOKEN_KEY } from 'providers/Auth/AuthProvider';

// Provides easy access to a pre-configured axios with an interceptor to handle auth
// Pretty much taken verbatim from: https://cretezy.com/2020/react-query-axios-authentication
export const AxiosContext = createContext<AxiosInstance>(undefined);

const AxiosProvider = ({ children }: React.PropsWithChildren<unknown>) => {
	const axios = useMemo(() => {
		const axiosClient = Axios.create({
			baseURL: process.env.REACT_APP_API_HOST,
			headers: {
				'Content-Type': 'application/json'
			}
		});

		axiosClient.interceptors.request.use(config => {
			// Get the token from local storage directly
			// This isn't ideal, but otherwise the auth provider and axios provider depend on each other
			const authToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`;
			}

			return config;
		});

		return axiosClient;
	}, []);

	return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
};

export const useAxios = () => {
	return useContext(AxiosContext);
};

export default AxiosProvider;
