import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClient } from 'react-query';
import { format, parse, isValid } from 'date-fns';
import {
	ApiResponse,
	BlobResponse,
	handleResponse,
	useAxiosRawRequest,
	EXPORT_DEFAULT_FORMAT,
	EXPORT_TIMEOUT
} from './api';
import { useAxios } from 'providers/Axios/AxiosProvider';
import { User } from './auth';
import { MandateProps } from 'common/types/TypeHelpers';
import { ExportFormat } from 'common/types/ExportFormat';
import { Organization } from './organization';
import { ApiReportingInput, useMeta, useReporting } from './reporting';

// SxanPro API matching types
export type DeviceContainer = {
	id: number;
	name: string;
	type: DeviceContainerType;
	parentId?: number;
	userId?: number;
	organizationId?: string;
	updatedAt?: string;
	createdAt?: string;
	deletedAt?: string;
	children?: DeviceContainer[];
	path?: FolderPathEntry[];
	hasAudits?: boolean;
};

export type DeviceContainerType = 'folder' | 'list';

export type Folder = {
	id: number;
	name: string;
	parent?: number;
	user_id: number;
	active: boolean;
	organization_id: string;
	created_at: string;
	updated_at: string;
	deleted_at?: string;
	inventories?: List[];
	subfolders?: Folder[];
	path?: FolderPathEntry[];
	hasAudits?: boolean;
	type: 'Folder';
};

export type FolderPathEntry = {
	id: number;
	parent_id?: number;
	name?: string;
};

export type List = {
	id: number;
	name: string;
	location: string;
	folder_id: number;
	user_id: number;
	organization_id: string;
	username: string;
	active: boolean;
	created_at: string;
	updated_at: string;
	deleted_at?: string;
	device_count: string;
	devices: Device[];
	path?: FolderPathEntry[];
	user?: User;
	type: 'List';
};

export type YesNoNA = 1 | 0 | '';

export type Device = {
	id: number;
	inventory_id: number;
	inventory?: List;
	user_id: number;
	organization_id: string;
	active: boolean;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	last_updated: string;
	manual: number;
	source: string;
	lookup_success: number;
	quantity: number;
	package_type: string;
	package_quantity: number;
	item_location: string;
	lot_number: string;
	expiration: string;
	item_removed: boolean;
	item_removed_on?: string;
	item_removed_by?: number;
	removed_by_user?: Partial<User>;
	manufacturing_date: string;
	price: string;
	facility_product_id: string;
	consignment: YesNoNA;
	physicians_preference: YesNoNA;
	notes: string;
	device_id: string;
	device_id_type: string;
	udi: string;
	brand_name: string;
	company_name: string;
	reference_number: string;
	catalog_number: string;
	single_use: YesNoNA;
	lot_batch: YesNoNA;
	serial_number: string;
	has_expiration: number;
	device_description: string;
	device_detail_name: string;
	device_detail_description: string;
	storage_handling_type: string;
	storage_handling_high: string;
	storage_handling_low: string;
	storage_handling_description: string;
	is_sterile: YesNoNA;
	sterilize_prior: YesNoNA;
	sterilization_method: string;
	attainia_id: string;
	building_services: string;
	cadid: string;
	client_asset_tag_1: string;
	client_asset_tag_2: string;
	client_asset_tag_3: string;
	color: string;
	condition: string;
	condition_note: string;
	cost_center_name: string;
	cost_center_number: string;
	current_estimated_value: string;
	data_requirement: YesNoNA;
	date_accepted: string;
	department: string;
	depth: string;
	embedded_light: YesNoNA;
	estimated_age: string;
	extended_depth: string;
	extended_height: string;
	facility: string;
	finish: string;
	furniture: YesNoNA;
	height: string;
	height_adjustable: YesNoNA;
	last_preventative_maintenance_date: string;
	recommended_preventative_maintenance_schedule: string;
	next_preventative_maintenance_date: string;
	level: string;
	mobile: YesNoNA;
	model_name: string;
	model_number: string;
	mounting_code: string;
	original_list_price_value: string;
	preventative_maintenance_note: string;
	reuse: YesNoNA;
	room_name: string;
	room_number: string;
	service_contract: YesNoNA;
	service_contract_expiration_date: string;
	service_contract_note: string;
	status: string;
	ups_required: YesNoNA;
	useful_life: string;
	voltage: string;
	weight: string;
	weighted: YesNoNA;
	width: string;
	images: string;
	transfer_sell_donate: string;
	premarket_510k: string;
	sales_price: string;
	cost_to_date: string;
	item_location_description: string;
	fda_product_code: string;
	fda_product_code_name: string;
	package_device_id: string;
	attachments?: DeviceAttachment[];
	is_temporary?: 0 | 1;
};

export type DeviceFieldName = keyof Device;

type DeviceUpdate = Omit<Device, 'attachments' | 'active' | 'last_updated'>;

export type DeviceAttachment = {
	id: number;
	device_id: number;
	uuid: string;
	type: AttachmentType;
	description?: string;
	original_filename?: string;
	content_type?: string;
	size?: number;
	created_at?: string;
	updated_at?: string;
	deleted_at?: string;
	thumbnail?: string;
};

export type AttachmentType = 'photo' | 'document';

// Convenience types
export type ApiFolderResponse = { folder: Folder };
type ApiFoldersResponse = { folders: Folder[] };
type ApiFolderSearchResponse = { results: DeviceContainer[] };
export type ApiListResponse = { list: List };
type ApiListsResponse = { lists: List[] };
type ApiDeviceResponse = { device: Device };

// Map an API Folder response to a type that can represent either a list or a folder
const mapFolderToDeviceContainer = (apiFolder: Folder): DeviceContainer => {
	let subFolders = [] as DeviceContainer[];
	if (apiFolder.subfolders) {
		subFolders = apiFolder.subfolders.map(folder => {
			return mapFolderToDeviceContainer(folder);
		});
	}

	let lists = [] as DeviceContainer[];
	if (apiFolder.inventories) {
		lists = apiFolder.inventories?.map(list => {
			return mapListToDeviceContainer(list);
		});
	}

	return {
		id: apiFolder.id,
		name: apiFolder.name,
		parentId: apiFolder.parent,
		userId: apiFolder.user_id,
		organizationId: apiFolder.organization_id,
		updatedAt: apiFolder.updated_at,
		createdAt: apiFolder.created_at,
		deletedAt: apiFolder.deleted_at,
		children: [...subFolders, ...lists],
		type: 'folder',
		path: apiFolder.path,
		hasAudits: apiFolder?.hasAudits ?? false
	};
};

// Map a list into the type used for inventory lists
const mapListToDeviceContainer = (apiList: List): DeviceContainer => {
	return {
		id: apiList.id,
		name: apiList.name,
		parentId: apiList.folder_id,
		userId: apiList.user_id,
		organizationId: apiList.organization_id,
		updatedAt: apiList.updated_at,
		createdAt: apiList.created_at,
		deletedAt: apiList.deleted_at,
		type: 'list',
		path: apiList.path
	};
};

// Make sure the device gets non-null initial values to help with form population
export const initializeDevice = (device: Partial<Device>): Device => {
	return {
		id: device.id ?? -1,
		inventory_id: device.inventory_id ?? -1,
		user_id: device.user_id ?? -1,
		organization_id: device.organization_id ?? '',
		active: device.active ?? false,
		created_at: device.created_at ?? '',
		updated_at: device.updated_at ?? '',
		deleted_at: device.deleted_at ?? '',
		last_updated: device.last_updated ?? '',
		manual: device.manual ?? 1,
		source: device.source ?? '',
		lookup_success: device.lookup_success ?? 0,
		quantity: device.quantity ?? 0,
		package_type: device.package_type ?? '',
		package_quantity: device.package_quantity ?? 0,
		item_location: device.item_location ?? '',
		lot_number: device.lot_number ?? '',
		expiration: cleanDateField(device.expiration) ?? '',
		item_removed: device.item_removed ?? false,
		item_removed_on: device.item_removed_on,
		item_removed_by: device.item_removed_by,
		manufacturing_date: cleanDateField(device.manufacturing_date) ?? '',
		price: cleanNumberField(device.price) ?? '',
		facility_product_id: device.facility_product_id ?? '',
		consignment: device.consignment ?? '',
		physicians_preference: device.physicians_preference ?? '',
		notes: device.notes ?? '',
		device_id: device.device_id ?? '',
		device_id_type: device.device_id_type ?? '',
		udi: device.udi ?? '',
		brand_name: device.brand_name ?? '',
		company_name: device.company_name ?? '',
		reference_number: device.reference_number ?? '',
		catalog_number: device.catalog_number ?? '',
		single_use: device.single_use ?? '',
		lot_batch: device.lot_batch ?? '',
		serial_number: device.serial_number ?? '',
		has_expiration: device.has_expiration ?? 0,
		device_description: device.device_description ?? '',
		device_detail_name: device.device_detail_name ?? '',
		device_detail_description: device.device_detail_description ?? '',
		storage_handling_type: device.storage_handling_type ?? '',
		storage_handling_high: device.storage_handling_high ?? '',
		storage_handling_low: device.storage_handling_low ?? '',
		storage_handling_description: device.storage_handling_description ?? '',
		is_sterile: device.is_sterile ?? '',
		sterilize_prior: device.sterilize_prior ?? '',
		sterilization_method: device.sterilization_method ?? '',
		attainia_id: device.attainia_id ?? '',
		building_services: device.building_services ?? '',
		cadid: device.cadid ?? '',
		client_asset_tag_1: device.client_asset_tag_1 ?? '',
		client_asset_tag_2: device.client_asset_tag_2 ?? '',
		client_asset_tag_3: device.client_asset_tag_3 ?? '',
		color: device.color ?? '',
		condition: device.condition ?? '',
		condition_note: device.condition_note ?? '',
		cost_center_name: device.cost_center_name ?? '',
		cost_center_number: device.cost_center_number ?? '',
		current_estimated_value: cleanNumberField(device.current_estimated_value) ?? '',
		data_requirement: device.data_requirement ?? '',
		date_accepted: cleanDateField(device.date_accepted) ?? '',
		department: device.department ?? '',
		depth: device.depth ?? '',
		embedded_light: device.embedded_light ?? '',
		estimated_age: device.estimated_age ?? '',
		extended_depth: device.extended_depth ?? '',
		extended_height: device.extended_height ?? '',
		facility: device.facility ?? '',
		finish: device.finish ?? '',
		furniture: device.furniture ?? '',
		height: device.height ?? '',
		height_adjustable: device.height_adjustable ?? '',
		last_preventative_maintenance_date:
			cleanDateField(device.last_preventative_maintenance_date) ?? '',
		recommended_preventative_maintenance_schedule:
			device.recommended_preventative_maintenance_schedule ?? '',
		next_preventative_maintenance_date:
			cleanDateField(device.next_preventative_maintenance_date) ?? '',
		level: device.level ?? '',
		mobile: device.mobile ?? '',
		model_name: device.model_name ?? '',
		model_number: device.model_number ?? '',
		mounting_code: device.mounting_code ?? '',
		original_list_price_value: cleanNumberField(device.original_list_price_value) ?? '',
		preventative_maintenance_note: device.preventative_maintenance_note ?? '',
		reuse: device.reuse ?? '',
		room_name: device.room_name ?? '',
		room_number: device.room_number ?? '',
		service_contract: device.service_contract ?? '',
		service_contract_expiration_date: cleanDateField(device.service_contract_expiration_date) ?? '',
		service_contract_note: device.service_contract_note ?? '',
		status: device.status ?? '',
		ups_required: device.ups_required ?? '',
		useful_life: device.useful_life ?? '',
		voltage: device.voltage ?? '',
		weight: device.weight ?? '',
		weighted: device.weighted ?? '',
		width: device.width ?? '',
		images: device.images ?? '',
		transfer_sell_donate: device.transfer_sell_donate ?? '',
		premarket_510k: device.premarket_510k ?? '',
		sales_price: cleanNumberField(device.sales_price) ?? '',
		cost_to_date: cleanNumberField(device.cost_to_date) ?? '',
		item_location_description: device.item_location_description ?? '',
		fda_product_code: device.fda_product_code ?? '',
		fda_product_code_name: device.fda_product_code_name ?? '',
		package_device_id: device.package_device_id ?? '',
		attachments: device.attachments ?? [],
		is_temporary: device.is_temporary ?? 0
	};
};

const cleanDateField = (fieldValue: string) => {
	if (!fieldValue) {
		return '';
	}

	if (!isValid(parse(fieldValue, 'yyyy-MM-dd', new Date()))) {
		const alternateFormatDate = parse(fieldValue, 'MM/dd/yyyy', new Date());
		if (isValid(alternateFormatDate)) {
			return format(alternateFormatDate, 'yyyy-MM-dd');
		}

		return '';
	}

	return fieldValue;
};

const numberRegex = /^\d+(\.\d{1,2})?$/;
const cleanNumberField = (fieldValue: string) => {
	if (!fieldValue) {
		return '';
	}

	const resultValue = fieldValue.replace('$', '');
	return numberRegex.test(resultValue) ? resultValue : '';
};

// Gets info about a folder and its immediate children
export const useFolder = (folderId?: string | number) => {
	const axios = useAxios();

	return useQuery(
		['folder', `${folderId}` ?? '-1'],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiFolderResponse>>(`/folders/${folderId ?? -1}`),
				false
			);
		},
		{
			enabled: typeof folderId === 'number' || typeof folderId === 'string',

			// The select will help transform the data coming form the api
			select: useCallback((data: ApiFolderResponse): DeviceContainer => {
				return mapFolderToDeviceContainer(data.folder);
			}, [])
		}
	);
};

// Gets info about an organization root folder
export const useOrganizationRootFolder = (organization: Organization) => {
	const axios = useAxios();

	return useQuery(
		['organizationRoot', `${organization.id}`],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiFolderResponse>>(`/folders/${organization.root_folder_id}`),
				false
			);
		},
		{
			enabled: !!organization,

			// The select will help transform the data coming form the api
			select: useCallback((data: ApiFolderResponse): DeviceContainer => {
				return mapFolderToDeviceContainer(data.folder);
			}, [])
		}
	);
};

// Due to how hooks work, there needs to be one hook to handle the two possible
// endpoints the folder page needs to hit
// This works by using the main folder hook (a bit of that data is needed anyway)
// and then hitting the search endpoint if needed. If not, the folder is passed through
export const useFolderSearch = (folderId: string | number, searchTerm: string) => {
	const axios = useAxios();
	const { data: folder } = useFolder(folderId);

	return useQuery(
		['folderSearch', `${folderId}` ?? '-1', searchTerm],
		async () => {
			if (searchTerm) {
				return handleResponse(
					axios.get<ApiResponse<ApiFolderSearchResponse>>(
						`/folders/${folderId ?? -1}/search?query=${searchTerm}`
					),
					false
				);
			}

			// if there was no search term, skip this part
			return null;
		},
		{
			enabled: !!folder,

			// The select will help transform the data coming form the api
			// This is a huge hack right now
			select: useCallback(
				(data: ApiFolderSearchResponse): DeviceContainer => {
					if (searchTerm) {
						return { ...folder, children: data.results };
					} else {
						return folder;
					}
				},
				[folder, searchTerm]
			)
		}
	);
};

export const forceFolderRefresh = (
	queryClient: QueryClient,
	folderId: number,
	organizationId: string,
	parentId: number,
	folderIdNew?: number
) => {
	queryClient.invalidateQueries(['folder', `${folderId}`]);
	queryClient.invalidateQueries(['list', `${folderId}`]);
	queryClient.invalidateQueries(['folder', `${parentId}`]);
	queryClient.invalidateQueries(['folder', `${folderIdNew}`]);
	queryClient.invalidateQueries(['folders']);
	queryClient.invalidateQueries(['organizationRoot', `${organizationId}`]);
	queryClient.invalidateQueries(['organizationUsers', `${organizationId}`]);
	queryClient.invalidateQueries(['recentActivity']);
};

export const useAddUpdateFolder = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (
			folder: MandateProps<Partial<Folder>, 'id' | 'organization_id' | 'parent'> & {
				parent_id?: number;
			}
		) => {
			const response = await (() => {
				if (folder?.id) {
					return axios.put<
						Partial<Folder & { parent_id?: number }>,
						AxiosResponse<ApiResponse<{ folder: Folder }>>
					>(`/folders/${folder.id}`, folder);
				} else {
					return axios.post<
						Partial<Folder & { parent_id?: number }>,
						AxiosResponse<ApiResponse<{ folder: Folder }>>
					>('/folders', folder);
				}
			})();

			if (response.status === 200) {
				return {
					success: response.data.success,
					error: '',
					data: response.data.data.folder
				} as ApiResponse<Folder>;
			}
		},
		{
			// Make sure any cached list of this user's org users is refreshed
			onSuccess: (data, folder) => {
				forceFolderRefresh(
					queryClient,
					folder.id,
					folder.organization_id,
					folder.parent,
					folder.parent_id
				);
			}
		}
	);
};

// Gets info about a folder and its immediate children
export const useDeletedFolders = (days?: number, folderId?: string | number) => {
	const axios = useAxios();

	const daysPart = days ? `?days=${days}` : '';
	const folderPart = folderId ? `&folder_id=${folderId}` : '';

	return useQuery(
		['deletedFolders'],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiFoldersResponse>>(`/folders/deleted${daysPart}${folderPart}`),
				false
			);
		},
		{
			// The select will help transform the data coming form the api
			select: useCallback((data: ApiFoldersResponse): DeviceContainer[] => {
				return data.folders.map(folder => mapFolderToDeviceContainer(folder));
			}, [])
		}
	);
};

export const useUpdateList = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (
			list: MandateProps<Partial<List>, 'id' | 'organization_id' | 'folder_id'> & {
				parent_id?: number;
			}
		) => {
			// Remove unwanted properties from the data object
			const response = await (() => {
				return axios.put<Partial<List>, AxiosResponse<ApiResponse<{ list: List }>>>(
					`/lists/${list.id}`,
					list
				);
			})();

			if (response.status === 200) {
				return {
					success: response.data.success,
					error: '',
					data: response.data.data.list
				} as ApiResponse<List>;
			}
		},
		{
			onSuccess: (data, list) => {
				forceFolderRefresh(
					queryClient,
					list.id,
					list.organization_id,
					list.parent_id,
					list.folder_id
				);
			}
		}
	);
};

export const useAddList = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (
			list: MandateProps<Partial<List>, 'id' | 'organization_id' | 'folder_id'> & {
				parent_id?: number;
			}
		) => {
			// Remove unwanted properties from the data object
			const response = await (() => {
				return axios.post<Partial<List>, AxiosResponse<ApiResponse<{ list: List }>>>(
					`/lists`,
					list
				);
			})();

			if (response.status === 200) {
				return {
					success: response.data.success,
					error: '',
					data: response.data.data.list
				} as ApiResponse<List>;
			}
		},
		{
			onSuccess: (data, list) => {
				forceFolderRefresh(
					queryClient,
					list.id,
					list.organization_id,
					list.parent_id,
					list.folder_id
				);
			}
		}
	);
};

export const useAddTemporaryDevice = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (device: MandateProps<Partial<Device>, 'inventory_id'>) => {
			const newDeviceData: Partial<Device> = {
				...device,
				is_temporary: 1,
				device_id: 'N/A',
				udi: 'N/A',
				package_type: 'each'
			};

			// Remove unwanted properties from the data object
			const response = await (() => {
				return axios.post<Partial<Device>, AxiosResponse<ApiResponse<{ device: Device }>>>(
					`/devices`,
					newDeviceData
				);
			})();

			if (response.status === 200) {
				return {
					success: response.data.success,
					error: '',
					data: response.data.data.device
				} as ApiResponse<Device>;
			}
		},
		{
			onSuccess: (data, list) => {
				forceFolderRefresh(queryClient, list.id, list.organization_id, 0, 0);
			}
		}
	);
};

export const useRemoveFolder = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (input: { folderId: number; organizationId: string; parentId: number }) => {
			await axios.delete(`/folders/${input.folderId}`);
		},
		{
			onSuccess: (data, input) => {
				forceFolderRefresh(queryClient, input.folderId, input.organizationId, input.parentId);
				queryClient.invalidateQueries(['deletedFolders']);
			}
		}
	);
};

export const useRestoreFolder = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (input: { folderId: number; organizationId: string; parentId: number }) => {
			await axios.patch(`/folders/${input.folderId}/restore`);
		},
		{
			onSuccess: (data, input) => {
				forceFolderRefresh(queryClient, input.folderId, input.organizationId, input.parentId);
				queryClient.invalidateQueries(['deletedFolders']);
			}
		}
	);
};

export const useRemoveList = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (input: { listId: number; organizationId: string; parentId: number }) => {
			await axios.delete(`/lists/${input.listId}`);
		},
		{
			onSuccess: (data, input) => {
				forceFolderRefresh(queryClient, input.listId, input.organizationId, input.parentId);
				queryClient.invalidateQueries(['deletedLists']);
			}
		}
	);
};

export const useRestoreList = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (input: { listId: number; organizationId: string; parentId: number }) => {
			await axios.patch(`/lists/${input.listId}/restore`);
		},
		{
			onSuccess: (data, input) => {
				forceFolderRefresh(queryClient, input.listId, input.organizationId, input.parentId);
				queryClient.invalidateQueries(['deletedLists']);
			}
		}
	);
};

export const useRemoveDevice = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (input: {
			deviceId: number;
			organizationId: string;
			parentId: number;
			listId: number;
		}) => {
			await axios.delete(`/devices/${input.deviceId}`);
		},
		{
			onSuccess: (data, input) => {
				forceFolderRefresh(queryClient, input.listId, input.organizationId, input.parentId);
			}
		}
	);
};

// Gets list data
export const useList = (listId: string | number) => {
	const axios = useAxios();

	return useQuery(
		['list', `${listId}`],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiListResponse>>(`/lists/${listId}?devices=none`),
				false
			);
		},
		{
			enabled: !!listId,

			select: useCallback((data: ApiListResponse) => {
				return data.list;
			}, [])
		}
	);
};

// Gets info about a folder and its immediate children
export const useDeletedLists = (days?: number, folderId?: string | number) => {
	const axios = useAxios();

	const daysPart = days ? `?days=${days}` : '';
	const folderPart = folderId ? `&folder_id=${folderId}` : '';

	return useQuery(
		['deletedLists'],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiListsResponse>>(`/lists/deleted${daysPart}${folderPart}`),
				false
			);
		},
		{
			// The select will help transform the data coming form the api
			select: useCallback((data: ApiListsResponse): DeviceContainer[] => {
				return data.lists.map(list => mapListToDeviceContainer(list));
			}, [])
		}
	);
};

export const useDevicesForList = (listId: number, options: ApiReportingInput) => {
	options.lists = [listId];
	return useReporting(options);
};

export const useListFilters = (listId: number) => {
	return useMeta([], [listId]);
};

export const useExportList = (listId: number) => {
	const axios = useAxios();

	return useAxiosRawRequest<BlobResponse, unknown, [ExportFormat?]>(
		(exportFormat: ExportFormat = EXPORT_DEFAULT_FORMAT) => {
			return axios.get(`/lists/${listId}/export?format=${exportFormat}`, {
				responseType: 'blob',
				timeout: EXPORT_TIMEOUT
			});
		},
		{
			success: response => {
				const responseContentType = response.headers['content-type'];
				const extension = responseContentType?.includes('sheet') ? '.xlsx' : '.csv';

				const suggestedFilename = `SxanPro_List_export_${format(
					new Date(),
					'yyyy_MM_dd_HH_mm_ss'
				)}${extension}`;

				return {
					blob: response.data as Blob,
					suggestedFilename
				};
			}
		}
	);
};

// Full device data
export const useDevice = (deviceId: string) => {
	const axios = useAxios();

	return useQuery(
		['device', `${deviceId}`],
		async () => {
			return handleResponse(
				axios.get<ApiResponse<ApiDeviceResponse>>(`/devices/${deviceId}`),
				false
			);
		},
		{
			enabled: !!deviceId,

			select: useCallback((data: ApiDeviceResponse) => {
				return initializeDevice(data.device);
			}, [])
		}
	);
};

export const useDeviceUpdate = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (device: Device) => {
			// Remove unwanted properties from the data object
			const { attachments, active, last_updated, ...deviceUpdate } = device;

			return handleResponse(
				axios.put<DeviceUpdate, AxiosResponse<ApiResponse<ApiDeviceResponse>>>(
					`/devices/${device.id}`,
					deviceUpdate
				),
				false
			);
		},
		{
			onSuccess: (data, device) => {
				queryClient.invalidateQueries(['device', `${device.id}`]);
				queryClient.invalidateQueries(['list', `${device.inventory_id}`]);
				queryClient.invalidateQueries(['reporting']);
			}
		}
	);
};

// This is a placeholder implementation for now
// Meant for the Dashboard
export const useRecentInventory = () => {
	const axios = useAxios();

	return useQuery(
		'recent-inventory',
		async () => {
			return handleResponse(axios.get<ApiResponse<ApiListsResponse>>('/lists'), false);
		},
		{
			select: useCallback((data: ApiListsResponse) => {
				return data.lists.map(list => {
					return list;
				});
			}, [])
		}
	);
};

// Gets the image data for the given device attachment
// Data is returned as a Blob
export const useDeviceAttachment = () => {
	const axios = useAxios();

	return async (deviceId: number, attachmentId: number) => {
		const response = await axios.get(`/devices/${deviceId}/attachments/${attachmentId}/data`, {
			responseType: 'blob',
			timeout: 30000
		});

		if (response.status === 200) {
			return response.data as Blob;
		}

		// error handling
	};
};

export const useUploadDeviceAttachment = () => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	const [isUploading, setIsUploading] = useState(false);
	const [error, setError] = useState(null);

	const upload = async (
		deviceId: number,
		files: File[],
		onUploadProgress?: (progressEvent: ProgressEvent) => void
	) => {
		setIsUploading(true);

		// Add the files to the form data
		const formData = new FormData();
		files.forEach(file => formData.append('attachments[]', file));

		const response = await axios.post(`/devices/${deviceId}/attachments`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress
		});

		setIsUploading(false);

		if (response.status === 200) {
			queryClient.invalidateQueries(['device', `${deviceId}`]);
		} else {
			setError(response.statusText);
		}
	};

	return { isUploading, error, upload };
};

export const useDeleteDeviceAttachment = (deviceId: number) => {
	const axios = useAxios();
	const queryClient = useQueryClient();

	return useMutation(
		async (attachmentId: number) => {
			return axios.delete(`/devices/${deviceId}/attachments/${attachmentId}`);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['device', `${deviceId}`]);
			}
		}
	);
};
