import React from 'react';

export const Authentication = {
	LoginView: React.lazy(() => import('./Authentication/LoginView'))
};

export const Inventory = {
	FolderView: React.lazy(() => import('./Inventory/FolderView')),
	ListView: React.lazy(() => import('./Inventory/ListView')),
	DeviceView: React.lazy(() => import('./Inventory/DeviceView')),
	DeletedInventoryView: React.lazy(() => import('./Inventory/DeletedInventoryView'))
};

export const Reporting = {
	ReportingView: React.lazy(() => import('./ReportingView')),
	UpcomingExpirationReportView: React.lazy(
		() => import('./ReportingView/UpcomingExpirationReportView')
	),
	InventoryOverviewReportView: React.lazy(() => import('./ReportingView/InventoryOverviewReportView'))
};
export const SamReporting = {
	SamReportingView: React.lazy(() => import('./SamReportingView/SamReportingView')),
	SamAssetOverviewReportView: React.lazy(
		() => import('./SamReportingView/SamAssetOverviewReportView/SamAssetOverviewReportView')
	)
};

export const DashboardView = React.lazy(() => import('./DashboardView'));

export const UserManagementView = React.lazy(() => import('./UserManagementView'));
export const OrganizationManagementView = React.lazy(() => import('./OrganizationManagementView'));
export const OrganizationDetailView = React.lazy(
	() => import('./OrganizationManagementView/OrganizationDetailView')
);
export const OrganizationCreateView = React.lazy(
	() => import('./OrganizationManagementView/OrganizationCreateView')
);

export const Audits = {
	AuditView: React.lazy(() => import('./Audits/AuditView')),
	AuditSelectorView: React.lazy(() => import('./Audits/AuditSelectorView')),
	AuditCreateEditView: React.lazy(() => import('./Audits/AuditCreateEditView'))
};

export const ExchangeTool = {
	ExchangeToolReportView: React.lazy(
		() => import('./ExchangeTool/ExchangeToolReportView/ExchangeToolReportView')
	)
};

export const SavedReports = {
	SavedReportsView: React.lazy(() => import('./SavedReports/SavedReportsView/SavedReportsView')),
	SavedExchangeReportView: React.lazy(
		() => import('./SavedReports/SavedExchangeReportView/SavedExchangeReportView')
	)
};
